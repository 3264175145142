import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchContact} from "../../api/redux/contacts";
import {Helmet, HelmetProvider} from "react-helmet-async";
import InnerBanner from "../../components/InnerBanner";
import ContactAddress from "./ContactAddress";
import ContactForm from "./ContactForm";
import MetaTag from "../../components/meta/MetaTag";


const MyComponent = () => {


    const dispath = useDispatch();

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.CONTACT
        dispath(fetchContact([api_url]))
    }, [])

    let getPost = useSelector(state => state.contact)

    // data refactor

    let banner=getPost?.posts?.[0]?.banner;
    let address=getPost?.posts?.[0]?.address;
    let form=getPost?.posts?.[0]?.form;
    let meta=getPost?.posts?.[0]?.meta;

    return (
        <HelmetProvider>
            <MetaTag title={'Contact Us | Brissie Estate Limited.'} description={meta?.meta_desc ? meta?.meta_desc : ''} ogTitle={meta?.og_title ? meta?.og_title : ""} ogDescription={meta?.og_desc ? meta?.og_desc : ''}/>



            <StyledComponent>
                <InnerBanner notFull title={banner?.title} subtext={banner?.subtitle}
                             img={banner?.image} mimg={banner?.mobile_image}/>
                <ContactAddress data={address}/>
                <ContactForm data={form}/>
            </StyledComponent>
        </HelmetProvider>
    );
};
const StyledComponent = styled.section`
`
export default MyComponent;