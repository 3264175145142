import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import ProjectList from "./ProjectList";
import {useSelector} from "react-redux";
import {ApiParam} from "../../api/network/apiParams";
import MetaTag from "../../components/meta/MetaTag";

const MyComponent = () => {


    let getPost = useSelector(state => state.project);

    // data refactor

    let banner=getPost?.posts?.[0]?.banner;
    let meta=getPost?.posts?.[0]?.meta;
    let list=getPost?.posts?.[0]?.list;
    let optionList=getPost?.posts?.[0]?.filter;

    // step -01

    const [selectStatus, setSelectStatus] = useState()
    const [selectType, setSelectType] = useState('')
    const [selectLocation, setSelectLocation] = useState('')


    // step -02

    // api call
    useEffect(() => {
        let param = {
            [ApiParam.status_id]: selectStatus,
            [ApiParam.type_id]: selectType,
            [ApiParam.location_id]: selectLocation
        }
    }, [selectStatus, selectType, selectLocation])


    return (
        <HelmetProvider>
            <MetaTag title={'Projects | Brissie Estate Limited.'} description={meta?.meta_desc ? meta?.meta_desc : ''} ogTitle={meta?.og_title ? meta?.og_title : ""} ogDescription={meta?.og_desc ? meta?.og_desc : ''}/>

            <StyledComponent>
                <InnerBanner notFull title={banner?.title}
                             subtext={banner?.subtitle}
                             img={banner?.image ? banner?.image : '/images/static/blur.jpg'}
                             mimg={banner?.mobile_image}
                />
                <ProjectList data={list} optionList={optionList}/>
            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
