import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import classNames from 'classnames';

const ThemeContainer = ({
                            children,
                            containerClass = null,
                            containerID = null,
                            rowClass,
                            colClassName,
                            fluid = false,
                            containerProps = {},
                            rowProps = {},
                            colProps = {},
                            colStyles = {},
                            colSizes = { xs: 12 },
                            colOffsets = {}
                        }) => {
    // Merge the custom class with the containerClassName
    const containerClassName = classNames(containerProps.className, {
        'container-fluid': fluid
    });

    return (
        <Container id={containerID ? containerID : ''} fluid={fluid} className={containerClassName+ ' '+containerClass}  {...containerProps}>
            <Row className={rowClass} {...rowProps}>
                {Object.entries(colSizes).map(([size, width]) => (
                    <Col className={colClassName ? colClassName : ''} style={colStyles} key={size} {...colProps} {...colOffsets[size]} {...{ [size]: width }}>
                        {children}
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default ThemeContainer;
