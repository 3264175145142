import React from 'react';

const SliderContent = ({ element }) => {
    return (
        <div className="text-slider__content">
            <h1>{element?.title}</h1>
        </div>
    );
};

export default SliderContent;
