import React from 'react';

const Mision = ({ data, title, description }) => {
    return (
        <div>
            <h4 className={'split-up'}>{title}</h4>
            <p className={'split-up'}>{description}</p>
        </div>
    );
};

export default Mision;
