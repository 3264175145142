import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {heavy} from "../styles/globalStyleVars";

const MyComponent = () => {

    return (
        <StyledComponent>

            <Container>
                <Row>
                    <Col md={12} className={'mb-5 mt-5'}>
                        <h1>Font Testing</h1>
                    </Col>
                </Row>
                <Row>
                    <Col md={7}>
                        <div className={'d-flex justify-content-between'}>
                            <h1>H1 </h1>
                            <h1 className={'heavy'}>H1 </h1>
                        </div>
                        <div className={'d-flex justify-content-between'}>
                            <h2>H2 </h2>
                            <h2 className={'heavy'}>H2 </h2>
                        </div>
                        <h3>H3</h3>
                        <h4>H4</h4>
                        <h5>Large Text</h5>
                        <h6>Button/Smallest Text</h6>
                        <p>SMALL TEXT</p>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};
const StyledComponent = styled.section`
  margin-top: 100px;
    h1,h2,h3,h4,h5,h6,p{
      margin-bottom: 20px;
    }
 
  .heavy{
    font-weight: 900;
    @media(max-width: 767px){
      display: none;
    }
  }

`
export default MyComponent;