import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {useSelector} from "react-redux";

export const Parallax = () => {
    const location = useLocation();
    gsap.registerPlugin(ScrollTrigger);
    let getPost = useSelector(state => state)

    useEffect(() => {
        let getRatio = el => window.innerHeight / (window.innerHeight + el.offsetHeight);

        gsap.utils.toArray(".parallax").forEach((section, i) => {

            if(window?.innerWidth > 767){
                section.bg = section.querySelector(".parallax-bg");
                let parallaxSpeed = section.getAttribute('data-speed');


                gsap.fromTo(section.bg, {
                    backgroundPosition: () => i ? `50% ${-window.innerHeight * getRatio(section)}px` : "50% 0px",
                }, {
                    backgroundPosition: () => `50% ${parallaxSpeed ? parallaxSpeed + 'px' : '350px'}`,
                    ease: "none",
                    scrollTrigger: {
                        trigger: section,
                        start: () => i ? "top bottom" : "top top",
                        end: "bottom top",
                        scrub: true,
                        invalidateOnRefresh: true // to make it responsive
                    }
                });

            }

        });
    }, [location.pathname,getPost])
}

/* how to use
 - Add 'parallax' class on the parent section. Add 'data-speed={speed string/number}' for parallax speed (if needed)
 - Add 'parallax' props on Img component.
*/


export const ParallaxImg = () => {
    const location = useLocation();
    let getPost = useSelector(state => state)

    useEffect(() => {
        if(window?.innerWidth > 767){
            gsap.utils.toArray(".parallax-img").forEach((item, i) => {
                let getImg = item.querySelector('img')
                let parallaxSpeed = item.getAttribute('data-speed');
                gsap.to(getImg, {
                    yPercent: parallaxSpeed ? parallaxSpeed : 15,
                    ease: "none",
                    scrollTrigger: {
                        trigger: item,
                        // markers: true,
                        scrub: true
                    }
                });
            });
        }

        if(window?.innerWidth > 767){
            gsap.utils.toArray(".parallax-img").forEach((item, i) => {
                let getImg = item.querySelector('.bg')
                let parallaxSpeed = item.getAttribute('data-speed');
                gsap.to(getImg, {
                    yPercent: parallaxSpeed ? parallaxSpeed : 15,
                    ease: "none",
                    scrollTrigger: {
                        trigger: item,
                        // markers: true,
                        scrub: true
                    }
                });
            });
        }


    }, [location.pathname,getPost])

}

/*
How to use:
- add 'parallax-img' to the parent div of Img component/img tag
- Add 'data-speed={speed string/number}' for parallax speed (if needed)
*/