import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import InnerBanner from "../../components/InnerBanner";
import MediaList from "./MediaList";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {useDispatch, useSelector} from "react-redux";
import {fetchMedia} from "../../api/redux/newsroom";
import {HelmetProvider} from "react-helmet-async";
import MetaTag from "../../components/meta/MetaTag";
const MyComponent = () => {

    const dispatch = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.MEDIA
        dispatch(fetchMedia([api_url]))
    }, [])

    let getPost = useSelector(state => state.media)


    // data refactor

    let medialist=getPost?.posts?.media;
    let banner=getPost?.posts?.page_data?.banner;
    let meta=getPost?.posts?.page_data?.meta;

    return (
        <HelmetProvider>
            <MetaTag title={'Media Center | Brissie Estate Limited.'} description={meta?.meta_desc ? meta?.meta_desc : ''} ogTitle={meta?.og_title ? meta?.og_title : ""} ogDescription={meta?.og_desc ? meta?.og_desc : ''}/>

            <StyledComponent>
                <InnerBanner notFull title={banner?.title} subtext={banner?.subtitle}
                             img={banner?.image} mimg={banner?.mobile_image}/>
                <MediaList data={medialist}/>

            </StyledComponent>
        </HelmetProvider>

    );
};
const StyledComponent = styled.section`
`
export default MyComponent;