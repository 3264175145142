import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import Button from "./Button";
import {heavy, medium} from "../styles/globalStyleVars";

const MyComponent = () => {

    return (
        <StyledComponent>
            <Container>
                <Row>
                    <Col md={12}>
                        <h1 className={'pb-100'}>Font Testing</h1>
                    </Col>
                    <Col md={12}>
                       <h1>H1</h1>
                       <h2>H2</h2>
                       <h3>H3</h3>
                       <h4>H4</h4>
                       <h5>H5</h5>
                       <h6>H6</h6>
                        <div className={'h7-text'}>
                            <p>H7</p>
                        </div>
                       <p>p</p>
                        <div className={'smallest-text'}>
                            <p>Smallest Text</p>
                        </div>
                        <div className={'footer'}>
                            <p>Footer</p>
                        </div>
                    </Col>
                    <Col md={12}>
                        <Button text={'button'} margin={'30px 0 60px 0'}/>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};
const StyledComponent = styled.section`
    .smallest-text {
      p{
        font-size: 12px;
        line-height: 20px;
        font-family: ${medium};
      }
    }
  .footer {
    p{
      font-size: 14px;
      line-height: 20px;
      font-family: ${medium};
    }
  }
  .h7-text{
    p{
      font-size: 16px;
      line-height: 24px;
      font-weight: 900 !important;
      font-family: "Avenir";
    }
  }

`
export default React.memo(MyComponent);