import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import MediaSingle from "../../components/media/parts/MediaSingle";
import ThemeRow from "../../theme/row";

const MyComponent = ({data}) => {

    return (
        <StyledComponent className={'pt-150 pb-150'}>
            <ThemeRow>
                {
                    data && data?.length>0 &&
                    data?.map((element)=>{
                        return(
                            <Col sm={4} key={element?.ID}>
                                <MediaSingle
                                    text={element?.hover_title}
                                    title={element?.title}
                                    img={element?.thumb}
                                    link={`/media-center/${element?.post_name}`}
                                    day={element?.date}
                                    month={element?.month}
                                    year={element?.year}

                                />
                            </Col>
                        )
                    })
                }
            </ThemeRow>
        </StyledComponent>
    );
};
const StyledComponent = styled.section`
    background: #F9F9F9;
    
    .col-sm-4{
      margin-bottom: 30px;
    }
    
    @media(max-width: 1024px) and (min-width: 768px){
        .col-sm-4{
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
`
export default MyComponent;