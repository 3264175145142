import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import SingleProject from "../../components/project/parts/SingleProject";
import Select, {components} from "react-select";
import {useHistory, useLocation} from "react-router-dom";
import {hover} from "../../styles/globalStyleVars";
import {useDispatch, useSelector} from "react-redux";
import {ApiParam} from "../../api/network/apiParams";
import {apiEndPoints, apiEndPoints as ApiServices} from "../../api/network/apiEndPoints";
import {fetchPosts} from "../../api/redux/project";
import ThemeRow from "../../theme/row";

const MyComponent = ({data,optionList}) => {

    const dispath = useDispatch();

    const [selectStatus, setSelectStatus] = useState('')
    const [selectType, setSelectType] = useState('')
    const [selectLocation, setSelectLocation] = useState('')
    const p_location = optionList?.project_location?.map(location => ({value: location?.slug,label: location?.name}));
    const selectTypeInputRef = useRef();
    const selectLocationInputRef = useRef();
    const key = 'label';

    const uniqueLocation = [...new Map(p_location?.map(item =>
        [item[key], item])).values()];

    const [isCompleted, setIsCompleted] = useState(false)
    const [index, setIndex] = useState(6)
    const initialData = data?.slice( 0, index);
    const history = useHistory();


    const hlocation = useLocation();

    //To get the params
    const paramStatus = new URLSearchParams(hlocation.search).get("status");

   // api call on filtering
    useEffect(() => {

        setSelectStatus(paramStatus);

        if(selectStatus===paramStatus){
            let param = {
                [ApiParam.status_id]: selectStatus,
                [ApiParam.type_id]: selectType,
                [ApiParam.location_id]: selectLocation,
            }
            let api_url = apiEndPoints.PROJECT
            dispath(fetchPosts([api_url,param]))
        }

    }, [selectStatus, selectType,selectLocation,paramStatus])



    const customStyles = {
        dropdownIndicator: (base, state) => ({
            ...base,
            transition: "all .2s ease",
            transform: state.selectProps.menuIsOpen && "rotate(180deg)",
        }),
        control: (base, state) => ({
            ...base,
            backgroundColor: 'transparent',
            borderColor: "#B8B8B8",
            borderRadius: 0,
            height: 60,
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                borderColor: "#222222",
                // cursor: 'pointer'
            },
        }),
        option: (styles, state) => ({
            ...styles,
            backgroundColor: state.isSelected ? hover : '#FFF',
            "&:hover": {
                backgroundColor: hover,
                color: '#FFF',
                // cursor: 'pointer'
            },
            zIndex : '99999',
            position: 'relative',
        }),
        indicatorContainer: (base, state) => ({
            ...base,
            transform: state.selectProps.menuIsOpen && "rotate(180deg)"
        }),
        menu: (base) => ({
            ...base,
            zIndex: 99999, // Increase the z-index value as needed
            fontSize:'12px',
            lineHeight:'16px'
        }),

    };

    const handleStatus = (e) => {
        setSelectStatus(e?.value);
        if(e?.value=='all'){
            history.push('/projects');
        }
        else{
            history.push(`/projects?status=${e?.value}`);
        }

        selectTypeInputRef?.current?.clearValue();
        selectLocationInputRef?.current?.clearValue();
    }

    const handleType = (e) => {
        setSelectType(e);
    }
    const handleLocation = (e) => {
        setSelectLocation(e);
    }
    const status=[
        {label:'All' ,value : 'all'},
        {label:'Our Footstep' ,value : 'ongoing'},
        {label:'Our Future Project' ,value : 'upcoming'},
        {label:'Our Achievements' ,value : 'completed'},
    ]
    const type=[
        {label:'Residential' ,value : 'residential'},
        {label:'Commercial' ,value : 'commercial'},
    ]

    const location=[
        {label:'Khilgoan' ,value : 'khilgoan'},
        {label:'Uttara' ,value : 'uttara'},
    ]

    const CaretDownIcon = () => {
        return <svg xmlns="http://www.w3.org/2000/svg" width="11.414" height="6.414" viewBox="0 0 11.414 6.414">
            <g id="Group_4823" data-name="Group 4823" transform="translate(10.707 0.707) rotate(90)">
                <line id="Line_9" data-name="Line 9" x2="5" y2="5" fill="none" stroke="#56575a" stroke-linecap="round" stroke-width="1"/>
                <line id="Line_10" data-name="Line 10" x1="5" y2="5" transform="translate(0 5)" fill="none" stroke="#56575a" stroke-linecap="round" stroke-width="1"/>
            </g>
        </svg>;
    };
    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <CaretDownIcon/>
            </components.DropdownIndicator>
        );
    };


    const store = useSelector(store => store)


    return (
        <StyledComponent className={'pt-60 '}>
            <ThemeRow>
                <Col md={4}>
                    <Select
                        onChange={handleStatus}
                        isSearchable={false}
                        styles={customStyles}
                        components={{DropdownIndicator}}
                        options={status}
                        placeholder={selectStatus ? selectStatus : paramStatus ? paramStatus : 'Status' }
                        defaultValue={selectStatus}
                        classNamePrefix={'react-select'}

                    />
                </Col>
                <Col md={4}>
                    <Select
                        styles={customStyles}
                        onChange={e => handleType(e?.value)}
                        options={type}
                        ref={selectTypeInputRef}
                        classNamePrefix={'react-select'}
                        components={{DropdownIndicator}}
                        isSearchable={false}
                        placeholder={'Type'}
                    />
                </Col>
                <Col md={4}>
                    <Select
                        styles={customStyles}
                        options={location}
                        ref={selectLocationInputRef}
                        onChange={e => { handleLocation(e?.value);}}
                        classNamePrefix={'react-select'}
                        components={{DropdownIndicator}}
                        isSearchable={false}
                        placeholder={'Location'}
                    />
                </Col>
                {
                    data && data?.length > 0  ?
                        data?.map((element,index) => {
                            return (
                                <Col md={4} key={element?.ID}>
                                    <SingleProject
                                        type={element?.project_type}
                                        src={`/project/${element?.post_name}`}
                                        img={element?.project_thumb}
                                        title={element?.post_title}
                                        subtitle={element?.project_location}
                                        icon={element?.icon}
                                        size={element?.size}
                                    />
                                </Col>
                            )
                        }) : <Col md={12} className={`hide-no-available ${store.project?.loading && 'hide' } `}><h6 className={'m-auto'}>No Project Found </h6></Col>
                }

            </ThemeRow>
        </StyledComponent>
    );
};
const StyledComponent = styled.section`

    padding-top: 60px;
    padding-bottom: 120px;

    
    .hide-no-available{
        opacity: 1;
        transition: 0.7s all ease;
        
        &.hide{
            opacity: 0;
            
        }
    }
    
    

    .react-select__menu {
        padding: 0;
        border-radius: 0;

        ul {
            margin: 0;
            padding: 0;
        }
    }

    .react-select__control {
        border-top: transparent;
        border-left: transparent;
        border-right: transparent;
        border-bottom: 1px solid #285854;
        height: 45px;
        cursor: pointer;
        &:hover {
            border-top: transparent;
            border-left: transparent;
            border-right: transparent;
            border-bottom: 1px solid #285854;
        }

        &:focus {
            border-top: transparent;
            border-left: transparent;
            border-right: transparent;
            border-bottom: 1px solid #285854;
        }
    }


    .react-select__indicator-separator {
        display: none;
    }

    .react-select__single-value {
        color: #285854 !important;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
    }
    
    .react-select__indicator{
        svg{
            line{
                stroke: #285854;
            }
        }
    }

    .css-b62m3t-container {
        margin-bottom: 40px;
    }

    .css-1fdsijx-ValueContainer {
        padding: 0;
    }

    .css-1jqq78o-placeholder {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        letter-spacing: 0.5px;
        text-transform: capitalize;
        color: #285854 !important;
    }


    @media (max-width: 767px) {
        padding-top: 80px;
        padding-bottom: 50px;
    }
`
export default MyComponent;