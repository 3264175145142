import React, {useEffect} from 'react';
import styled from "styled-components";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import Bod from "./Bod";
import {useDispatch, useSelector} from "react-redux";
import InnerBanner from "../../components/InnerBanner";
import Overview from "../../components/Overview";
import Vision from "./VisionMission";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchAbout} from "../../api/redux/about";
import useScrollToTop from "../../hooks/useScrollToTop";
import MetaTag from "../../components/meta/MetaTag";
import Manager from "./Manager";

const MyComponent = () => {


    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.ABOUT
        dispath(fetchAbout([api_url]))
    }, [])

    let getPost = useSelector(state => state.about)

    // data refactor

    let banner=getPost?.posts?.[0]?.banner;
    let management=getPost?.posts?.[0]?.management;
    let mission_vision=getPost?.posts?.[0]?.mission_vision;
    let overview=getPost?.posts?.[0]?.overview;
    let ceo=getPost?.posts?.[0]?.ceo;
    let corporate_value=getPost?.posts?.[0]?.corporate_value;
    let concern=getPost?.posts?.[0]?.sister_concern;
    let testimonial=getPost?.posts?.[0]?.testimonial;
    let timeline=getPost?.posts?.[0]?.timeline;
    let meta=getPost?.posts?.[0]?.meta;

    let overview_background = overview?.background;


    useScrollToTop([getPost]);
    return (
        <HelmetProvider>

            <MetaTag title={'Who We Are | Brissie Estate Limited.'} description={meta?.meta_desc ? meta?.meta_desc : ''} ogTitle={meta?.og_title ? meta?.og_title : ""} ogDescription={meta?.og_desc ? meta?.og_desc : ''}/>
            <StyledComponent overview_background={overview_background}>
                <InnerBanner title={banner?.title} subtext={banner?.subtitle} notFull
                             img={banner?.image} mimg={banner?.mobile_image}/>
                <div className="overview-bg">
                    <Overview desc={overview?.description} title={overview?.title} pdf={overview?.pdf} bgcolor={'transparent'} nobg hasbtn about/>
                    <Vision bgcolor={'transparent'} data={mission_vision} zIndex={'0'}/>
                </div>
                <Manager image={ceo?.image} title={ceo?.title} description={ceo?.description}/>
                {/*<Bod data={management} testimonial={testimonial}/>*/}
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`
    
    .overview-bg{
        background-image: url(${props => (props.overview_background || '/images/static/overview-bg.jpg')});
        background-size: cover; // You can adjust the sizing as needed
        background-repeat: no-repeat;
        background-position: center center;
    }

`;

export default MyComponent;
