import React from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";
import {ImageParallax} from "../../../components/ImageParallax";
import {white} from "../../../styles/globalStyleVars";

const InnerBanner = ({img,title, size,project,notFull,location,type,icon,mimg}) => {
    return (
        <StyledInnerBanner notFull={notFull} project={project} className='InnerBanner parallax '>
            {
                window?.innerWidth>767 ?
                    img && <ImageParallax parallax banner={true} src={img ? img : '/images/dynamic/project/blur.jpg'} />
                     :  <ImageParallax banner={true} src={mimg ? mimg : img} />
            }

            <Container>
                <Row>
                    <Col md={{span:8}}>
                        <h1>{title ? reactHtmlParser(title) : ''}</h1>
                        <div className={'address'}>
                            <h6>{type}</h6>
                            <span />
                            <h6>{location}</h6>
                            <span />
                            <h6>{size}</h6>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledInnerBanner>
    );
};

const StyledInnerBanner = styled.section`
    padding-top: ${props => props.notFull ? '75vh' : '100vh'};
    position: relative;
    height: ${props => props.notFull ? '75vh' : '100vh'};
    overflow: hidden;

    &:after {
        position: absolute;
        background: linear-gradient(142deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.5018601190476191) 0%);
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
    }

    span {
        width: 1px;
        height: 12px;
        background-color: #FFFFFF;
        margin: 0 15px;
    }

    .container {
        position: absolute;
        margin: auto;
        left: 0;
        text-align: center;
        z-index: 2;
        right: 0;
        bottom: 40px;
        height: auto;
        text-align: center;
        z-index: 2;

        img {
            margin-bottom: 20px;
        }

        h1 {
            font-size: 60px;
            font-weight: 500;
            line-height: 68px;
            color: #FFFFFF;
            margin-bottom: 30px;
        }

        h6 {
            color: ${white};
        }


        .up-title {
            margin-bottom: 35px;
        }

        .row {
            height: 100%;
            align-items: center;
            justify-content: center;
        }

        .address {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            padding: 40px;
            background: rgba(255, 255, 255, 0.1);
            backdrop-filter: blur(10px);
            h6{
                font-size: 12px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0.5px;
                text-transform: uppercase;
            }
            
            @media(max-width: 600px){
                padding: 20px;
            }
        }
    }

    @media (max-width: 767px) {

        .container {
            bottom: 40px;

            .address {
                justify-content: start;
            }

            h1 {
                font-size: 36px;
                line-height: 40px;
                margin-bottom: 20px;
            }
        }
    }
`;

export default InnerBanner;
