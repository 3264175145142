import React, {useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Title from "../../../components/Title";
import ReactHtmlParser from "react-html-parser";
import {Img} from "../../../components/Img";
import Box from "../../../components/Box";
import {grayText, hover, whites} from "../../../styles/globalStyleVars";
import ThemeContainer from "../../../theme/container";

const Feature = ({data}) => {



    const settings = {
        dots: true,
        rows: 2,
        slidesPerRow: 1,
        infinite: false,
        autoplay: false,
        speed: 800,
        slidesToShow: 5,
        slidesToScroll: 1,
        nextArrow: false,
        prevArrow: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,

                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    rows: 2,
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    };

    const [state, setState] = useState(false);

    return (
        <StyledComponent id={'features'} className='feature pt-150 pb-150'>
            <div className={'bg-noise'}/>
            <ThemeContainer colProps={{md: 12}}>
                <div className="feature__title">
                    <Title fontSize={48} fontWeight={500} lineHeight={56} color={'#285854'} text={data?.title} margin={'0 0 40px 0'} center/>
                </div>
                <div className="slider" style={{width: '100%'}}>
                    {
                        data?.list && data?.list?.length > 0 &&
                        <Slider {...settings}>
                            {
                                data?.list && data?.list?.length > 0 &&
                                data?.list?.map((element) => {
                                    return (
                                        <div className='feature__single'>
                                            <Box title={element?.title} icon={element?.icon}/>
                                        </div>
                                    )
                                })
                            }
                        </Slider>
                    }
                </div>
            </ThemeContainer>
        </StyledComponent>
    );
};
const StyledComponent = styled.section`
    margin-top: -2px;
    position: relative;
    overflow: hidden;

    .slick-track{
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .bg-noise {
        position: absolute;
        inset: 0;
        overflow: hidden;
        width: 100%;
        height: 100%;
        background-color: #FFFFFF;

        &:after {
            content: "";
            background-image: url('/images/static/noise-new.svg');
            background-blend-mode: multiply;
            background-size: cover;
            background-repeat: no-repeat;
            width: 100%;
            height: 100%;
            position: absolute;
            inset: 0;
            opacity: 1
        }
    }

    .slick-dots li.slick-active button {
        background: ${hover} !important;
    }

    .shadow-right {
        position: absolute;
        top: 0;
        right: -145px;
        height: fit-content;
    }


    .col {
        padding-left: 0px;
        padding-right: 0px;
    }

    .row {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .feature {
        &__row {
            margin-bottom: 60px;
            @media (max-width: 767px) {
                margin-bottom: 40px;
            }
        }

        &__title {
            //margin-bottom: 42px;
            @media (max-width: 767px) {
                margin-bottom: 20px;
            }

        }

        &__disclaimer {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            height: 100%;
            gap: 6px;

            p {
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                color: #B2A89F;
                height: 22px;

            }
        }

        &__single {
            //padding: 40px 72px 0px 72px;
            padding-top: calc(234 / 234 * 100%);
            position: relative;
            //cursor: pointer;
            margin-bottom: -8px;
            overflow: hidden;

        }
    }

    //customize button 

    .slick-dots {
        display: flex;
        justify-content: center;
        gap: 30px; /* Set a gap of 30px between dots */
    }

    .slick-dots li {
        width: 20px; /* Set the width for non-active dots */
    }

    .slick-dots li button {
        width: 100%;
        height: 2px; /* Set the height for all dots */
        padding: 3px;
        background: rgba(86, 87, 90, 0.5); /* Change the background color for all dots */
        border-radius: 20px;

        &:before {
            content: '';
            display: block;
        }
    }

    .slick-dots li.slick-active {
        width: 60px; /* Set the width for active dots */

        button {
            background: #56575A; /* Change the background color for all dots */
        }
    }


    @media (max-width: 991px) {
        .shadow-right {
            position: absolute;
            bottom: 0;
            right: 0;
            transform: translateX(75%);

            svg {
                width: 40%;
            }
        }

        .shadow-left {
            transform: translateX(-20%);
            left: 0;

            svg {
                width: 50%;
            }
        }
    }

    @media (max-width: 767px) {
        padding-bottom: 160px !important;
        .feature__single__img {
            top: 10px;
        }

        .feature__single h2 {
            top: 110px;
        }
    }

    .slick-track {
        display: flex !important;
        height: 100%;
    }

    .slick-slide {
        //height: auto;

        .slick-slide > div {
            //height: 100%;

            .myItemClass {
                height: 100%;
            }
        }
    }


    @media (max-width: 767px) {
        margin-left: -15px;
        margin-right: -15px;
        .title {
            margin-bottom: 0;
        }

        .slick-dots {
            text-align: start;
        }
    }

    .slick-prev {
        display: none !important;
    }

    .slick-next {
        display: none !important;
    }

    .slick-list {
        border: 1px solid rgba(178, 168, 159, 0.5);
    }

    .slick-slide {
        border-right: 1px solid rgba(178, 168, 159, 0.5);

        > div:nth-of-type(1) {
            border-bottom: 1px solid rgba(178, 168, 159, 0.5);
        }
    }

    .slick-dots {
        bottom: -60px;
    }


`;
export default Feature;