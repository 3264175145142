import React from 'react';

const SliderNavigation = () => {
    return (
        <div className={'d-flex justify-content-start'}>
      <span>
                            <svg className={'banner-slider_prev'} xmlns="http://www.w3.org/2000/svg" width="72.414" height="22.828" viewBox="0 0 72.414 22.828">
                                <g id="Arrow_-_Left" data-name="Arrow - Left" transform="translate(1.414 1.414)">
                                    <g id="Group_4823" data-name="Group 4823">
                                        <line id="Line_9" data-name="Line 9" x1="10" y2="10" fill="none" stroke="#edeeef" stroke-linecap="round" stroke-width="2"/>
                                        <line id="Line_10" data-name="Line 10" x2="10" y2="10" transform="translate(0 10)" fill="none" stroke="#edeeef" stroke-linecap="round" stroke-width="2"/>
                                    </g>
                                    <line id="Line_11" data-name="Line 11" x1="70" transform="translate(0 10)" fill="none" stroke="#edeeef" stroke-linecap="round" stroke-width="2"/>
                                </g>
                            </svg>
                        </span>
            <span>
                            <svg className={'banner-slider_next'} xmlns="http://www.w3.org/2000/svg" width="72.414" height="22.828" viewBox="0 0 72.414 22.828">
                                <g id="Arrow_-_Right" data-name="Arrow - Right" transform="translate(-587.5 -1590.086)">
                                    <g id="Group_4823" data-name="Group 4823" transform="translate(653.5 1596.5)">
                                        <line id="Line_9" data-name="Line 9" x2="10" y2="10" transform="translate(-5 -5)" fill="none" stroke="#edeeef" stroke-linecap="round" stroke-width="2"/>
                                        <line id="Line_10" data-name="Line 10" x1="10" y2="10" transform="translate(-5 5)" fill="none" stroke="#edeeef" stroke-linecap="round" stroke-width="2"/>
                                    </g>
                                    <line id="Line_11" data-name="Line 11" x2="70" transform="translate(588.5 1601.5)" fill="none" stroke="#edeeef" stroke-linecap="round" stroke-width="2"/>
                                </g>
                            </svg>
                        </span>
        </div>
    );
};

export default SliderNavigation;
