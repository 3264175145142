
//----------------------------------------------    Color Variables   --------------------------------------------------------------//


export const text = '#56575A';
export const hover = '#285854';
export const hoverSecond = '#24717D';
export const white = '#FFFFFF';
export const grayText = '#D7D8DA';


//----------------------------------------------    Font Variables   --------------------------------------------------------------//

export const title = `"Gustavo", Euclid Square, Arial, Helvetica, freesans, sans-serif `;
export const book = `"Aeonik"`;
export const black = `"Aeonik"`;
export const heavy = `"Aeonik"`;
export const medium = `"Aeonik"`;
export const light = `"Aeonik"`;



//
export const whites = '#F5F5F5';
export const ease = 'cubic-bezier(0.4, 0, 0, 1)';
export const body = `Banana Grotesk, Arial, Helvetica, freesans, sans-serif`

