import React from 'react';
import styled from "styled-components";
import {Col} from "react-bootstrap";
import Title from "../../../components/Title";
import {LightgalleryItem, LightgalleryProvider} from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";
import {Img} from "../../../components/Img";
import ThemeContainer from "../../../theme/container";
import ThemeRow from "../../../theme/row";

const Gallery = ({data, padding}) => {
    const PhotoItem = ({image, group}) => (
        <LightgalleryItem group={group} src={image}>
            <Img src={image}/>
        </LightgalleryItem>
    );


    return (
        <StyledGallery className={`gallery ${padding ? padding : 'pt-150 pb-150'}`}>

            {
                data?.title &&
                <ThemeContainer colProps={{md: 12}}>
                    <div className="feature__title">
                        <Title text={data?.title} margin={'0 0 40px 0'} color={'#285854'} fontSize={48} fontWeight={400} lineHeight={56} center/>
                    </div>
                </ThemeContainer>

            }



            <ThemeRow fluid containerClass={'gallery__image'}>
                <LightgalleryProvider
                    lightgallerySettings={
                        {
                            download:false
                        }
                    }
                >
                    {
                        data?.list && data?.list.length>0 &&
                        data?.list.map((element,index)=>{
                            return(
                                <Col lg={3} sm={6} key={index}>
                                    <div className="fixed_height">
                                        <PhotoItem image={element?.image} group={'1'}/>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </LightgalleryProvider>
            </ThemeRow>
        </StyledGallery>
    );
};
const StyledGallery = styled.section`
    margin-bottom: -30px;
    overflow: hidden;

    .fixed_height {
        padding-top: calc(300 / 333 * 100%);
        cursor: pointer;
    }

    .gallery__image {
        margin-bottom: 30px;

        .row {
            margin-left: -15px;
            margin-right: -60px;
            @media (max-width: 767px) {
                margin-left: -23px;
                margin-right: -23px;
            }
        }

        @media (min-width: 768px) and (max-width: 1550px) {
            .col-lg-3 {
                padding: inherit;
                margin-bottom: 0;
                margin-right: 0;
                flex: 0 0 25%;
                max-width: 25%;
            }
        }
        @media (min-width: 1551px) {
            .col-lg-3 {
                padding: inherit;
                margin-bottom: 0;
                margin-right: 0;
                flex: 0 0 25%;
                max-width: 25%;
            }
        }
        @media (max-width: 767px) {
            .col-sm-6 {
                padding: inherit;
                margin-bottom: 0;
                margin-right: 0;
                flex: 0 0 50%;
                max-width: 50%;
            }
        }

        .single {
            cursor: pointer;
        }
    }

    @media (max-width: 767px) {
        .title {
            h2 {
                text-align: start;
            }
        }
    }

    @media (min-width: 1550px) {
        .title {
            h2 {
                font-size: 45px;
                line-height: 55px;
            }
        }
    }
`

export default Gallery;