import styled from "styled-components";
import VisibilitySensor from "react-visibility-sensor";
import {Link} from "react-router-dom";
import {heavy, hover, medium} from "../../../styles/globalStyleVars";
import {Img} from "../../Img";

const BlogSingle = ({title, text, link, img, day, month, year}) => {
    return (
        <StyledBlogSingle className='blog-single fade-up'>
            <div className="blog-single__inner">
                <Link to={link || '/media/1'}><a/></Link>
                <Img src={img} objectFit={'cover'} layout={'fill'} alt={title}/>
                <div className="blog-single__inner__content">
                    <div className="blog-single__inner__content__top">
                        <p>{text}</p>
                        <h5 className={'split-up'}>{title}</h5>
                    </div>
                    {
                        day && month && year ?
                            <div className="blog-single__inner__content__bottom split-up">
                                <p >{day}</p>
                                <h6 >{month}
                                  <span >{year}</span></h6>
                            </div>
                            : ''
                    }

                </div>
            </div>
        </StyledBlogSingle>
    );
};

const StyledBlogSingle = styled.div`
    //width: 100%;

    .blog-single__inner {
        padding-top: calc(460 / 370 * 100%);
        position: relative;

        a {
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            z-index: 3;
        }

        &__content {
            &:after {
                content: '';
                height: 100%;
                width: 100%;
                background-color: #24717D !important;
                bottom: 0;
                left: 0;
                right: 0;
                position: absolute;
                transition: height .4s ease;
            }

            &__top {
                p {

                    color: #EDEEEF;
                    position: absolute;
                    left: 40px;
                    top: 40px;
                    z-index: 2;
                    right: 40px;
                    margin: 0;
                    transform: translateY(-30px);
                    opacity: 0;
                    transition: all .6s ease;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 7;
                    -webkit-box-orient: vertical;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 27px;
                    margin: 0;
                }

                h5 {
                    position: absolute;
                    top: 40px;
                    left: 40px;
                    right: 40px;
                    z-index: 2;
                    color: #FFFFFF;
                    margin: 0;
                    transition: all .3s ease;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 5;
                    -webkit-box-orient: vertical;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 27px;
                }
            }

            &__bottom {
                position: absolute;
                margin: 0;
                left: 40px;
                right: 40px;
                bottom: 24px;
                display: flex;
                justify-content: space-between;
                border-top: 1px solid #221F1F;
                padding-top: 20px;
                z-index: 2;
                border-color: #EDEEEF;

                p{
                    font-family: 'Gustavo';
                    font-size: 48px;
                    font-weight: 500;
                    line-height: 48px;
                    margin: 0;
                    color: #FFFFFF;
                }

                h6 {
                    color: #EDEEEF;
                    text-align: right;
                    transition: color .3s ease;

                    span {
                        display: block;
                        color: #EDEEEF;
                    }
                }
            }
        }

        &:hover {

            .blog-single__inner__content__top {
                h5 {
                    opacity: 0;
                    transform: translateY(-20px);
                }

                p {
                    transform: none;
                    opacity: 1;
                }
            }

            .blog-single__inner__content__bottom {
                border-color: ${hover} !important;

                p {
                   // color: ${hover};
                }

                h6 {
                    // color: ${hover};

                    span {
                        // color: ${hover};
                    }
                }
            }
        }

        &:hover {
            .blog-single__inner__content:after {
                //height: 100%;
                height: 0;
            }
        }
    }

    @media (max-width: 767px) {
        .blog-single__inner__content__top {
            h5, h2 {
                left: 30px;
                right: 30px;
                top: 30px;
            }
        }

        .blog-single__inner__content__bottom h1, .blog-single__inner__content__bottom h6 {
            left: 30px;
            right: 30px;
            top: 30px;
        }

    }

`;
export default BlogSingle;
