import React from 'react';
import styled from "styled-components";
import {Col, Container} from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import ButtonV2 from "./ButtonV2";
import Title from "./Title";
import ThemeRow from "../theme/row";

const MyComponent = ({onlyDescription, title, subTitle, link, data, desc, nobg, hasbtn, bg, about, pdf, bgcolor}) => {


    return (
        <StyledComponent id={'overview'} bgcolor={bgcolor} className={'about pt-150 pb-150'} nobg={nobg} hasbtn={hasbtn} bg={bg} about={about}>

                {
                    onlyDescription ?
                        <ThemeRow rowClass={'black_text'}>
                            <Col sm={{span: 12}}>
                                {
                                    title ?
                                        <div className={'d-flex justify-content-start align-items-start mobile'}>
                                            <Title text={title} textTransform={'none'} fontSize={40} lineHeight={48} fontWeight={500} center margin={'0 0 60px 0'} color={'white'} fontFamily={`"Avenir LT Pro 65 black"`}/>
                                        </div> : ''
                                }


                            </Col>
                            <Col sm={{span: 12}}>
                                <div className={'para split-up'}>
                                    {ReactHtmlParser(desc)}
                                </div>
                                {
                                    hasbtn ?
                                        <ButtonV2 color={'white'} borderColor={'white'} text={'DOWNLOAD BROCHURE'} margin={'60px 0px 0px 0'} download file={pdf} target={'_blank'}/> : ''
                                }
                            </Col>
                        </ThemeRow>
                        :
                        <ThemeRow>
                            <Col sm={{span: 8}}>
                                {
                                    title ?
                                        <div className={'d-flex justify-content-start align-items-start mobile'}>
                                            <Title text={title} textTransform={'none'} fontSize={40} lineHeight={48} fontWeight={500} center margin={'0 0 60px 0'} color={'white'} fontFamily={`"Avenir LT Pro 65 black"`}/>
                                        </div> : ''
                                }


                            </Col>
                            <Col sm={4}>
                            </Col>
                            <Col sm={{span: 7, offset: 5}}>
                                <div className={'para split-up'}>
                                    {ReactHtmlParser(desc)}
                                </div>
                                {
                                    hasbtn ?
                                        <ButtonV2 color={'white'} borderColor={'white'} text={'DOWNLOAD BROCHURE'} margin={'60px 0px 0px 0'} download file={pdf} target={'_blank'}/> : ''
                                }
                            </Col>
                        </ThemeRow>

                }
        </StyledComponent>
    );
};

const StyledComponent = styled.section`

  background-color:  ${props => props.bgcolor ? props.bgcolor : `#EDEEEF`} ;
  background-image: ${props => props.nobg ? 'none' : `url(${props.bg})`};
  background-size: cover; 
  background-repeat: no-repeat;
  background-position: center center;

    .black_text{

        p{
            color: #000000;
            margin-bottom: 25px;
            &:last-child{
                margin-bottom: 0;
            }
        } 
    }
    
    p{
        color: white;
    }
  h5{
    text-align: center;
    margin-bottom: 28px;
    &:last-child{
      margin-bottom: 0px;
    }
  }
    
    .title{
        h2{
            text-transform: none;
            text-align: left;
        }
    }
    .para{
        p{
            font-family: 'Aeonik';
            font-size: 18px;
            font-weight: 400;
            line-height: 26px;
        }
    }



  .shadow-right {
    position: absolute;
    top: 10px;
    right: -145px;
    //height: Vi
    margin-bottom: 28px !important;

    @media(max-width: 767px){
      text-align: start;
    }


    &:last-child{
      margin-bottom: 0px;
    }
    
  }
  .about__text {
    text-align: center;
  }



  @media (max-width: 991px) {
      
     .col-sm-8, .col-sm-7.offset-sm-5{
         flex: 0 0 100%;
         max-width: 100%;
         margin: 0;
     } 
    .about__text {
      min-width: 100%;
      margin: 0;
    }

    .shadow-right {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translateX(75%);

      svg {
        width: 40%;
      }
    }

    .shadow-left {
      transform: translateX(-20%);
      left: 0;

      svg {
        width: 80%;
      }
    }
  }

  @media (max-width: 767px) {

      padding-top: 80px !important;
      padding-bottom: 80px !important;
    .mobile{
      justify-content: start !important;
      .dc-btn{
        margin: 40px 0 0 !important;
      }
    }
    h5{
      text-align: start;
    }
    .about__text {
      h3 {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 20px;
      }

      p {
        width: 100%;
      }
    }

    .shadow-right {
      position: absolute;
      bottom: 0px;
      right: -20px;
      transform: none;
      //display: none;

      svg {
        //width: 40px;
        height: 100px;
      }
    }

    .shadow-left {
      transform: none;
      left: 0;
      bottom: 0px;
      top: auto;
      //display: none;

      svg {
        //width: 30px;
        height: 50px;
      }
    }

    .links {
      margin-top: 50px;
    }
  }


`;

export default MyComponent;
