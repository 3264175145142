import React from 'react';
import styled from "styled-components";
import {Col} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";
import {ImageParallax} from "./ImageParallax";
import {white} from "../styles/globalStyleVars";
import ThemeRow from "../theme/row";


const InnerBanner = ({img, subtext, title, uptitle,project,notFull,parallax,mimg}) => {
    return (
        <StyledInnerBanner notFull={notFull} project={project} className='InnerBanner parallax'>
            {
                window?.innerWidth>767 ?
                    <ImageParallax parallax banner={true} src={img ? img : '/images/static/blur.jpg'}/> :
                    <ImageParallax banner={true} src={mimg ? mimg : img}/>
            }
            <ThemeRow>
                <Col md={{span:8}}>
                    <h1 >{title ? reactHtmlParser(title) : ''}</h1>
                    <h6 >{subtext ? reactHtmlParser(subtext) : ''}</h6>
                </Col>
            </ThemeRow>
        </StyledInnerBanner>
    );
};

const StyledInnerBanner = styled.section`
  padding-top: ${props => props.notFull ? '75vh' : '100vh'} ;
  position: relative;
  height:${props => props.notFull ? '75vh' : '100vh'};
  overflow: hidden;

 
    
    
    
  &:after {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }

  .container {
    position: absolute;
    height: 100%;
    margin: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 2;

    h1 {
     margin: 0;
     font-size: 60px;
     font-weight: 500;
        line-height: 68px;
        text-transform: uppercase;
        color: #FFFFFF;
    }
      p{
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0.75px;
      }
    h6{
      color: ${white};
      text-transform: uppercase;
    }



    .up-title {
      margin-bottom: 35px;
    }

    .row {
      height: 100%;
      align-items: center;
      justify-content: center;
    }
  }
  
  @media(max-width: 767px){
    //padding-top: 0;
    height: auto;
    padding-top: 75vh ;

    .container {
      position: relative;
      top: auto;
      left: auto;
      transform: none;
      text-align: left;
      margin-bottom: 60px;

      h1 {
        font-size: 36px;
        line-height: 40px;
        margin-bottom: 20px;
      }
    }
  }




`;

export default InnerBanner;
