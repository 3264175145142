import React, {useEffect} from 'react';
import styled from "styled-components";
import {Col} from "react-bootstrap";
import {useHistory} from "react-router-dom"
import ReactHtmlParser from "react-html-parser";
import {CSSPlugin, gsap} from "gsap";
import {ScrollSmoother} from "gsap/ScrollSmoother";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import Title from "../../components/Title";
import ButtonV2 from "../../components/ButtonV2";
import ThemeRow from "../../theme/row";
import {Img} from "../../components/Img";


gsap.registerPlugin(ScrollTrigger, ScrollSmoother, CSSPlugin);

const MyComponent = ({title, subTitle, link, data, desc, nobg, hasbtn, bg, imageSrc}) => {

    const history=useHistory();


    // gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

    useEffect(() => {
        let scroller = ScrollSmoother.create({});

        if (window.innerWidth > 768) {
            // gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

            // let scroller = ScrollSmoother.create({});
            scroller.effects('.new-parallax', {
                speed: 1.3,
                lag: 0,
                top: '0px',
                bottom: '0px'
            });
        }
    }, [history?.location?.pathname]);


    return (
        <StyledComponent id={'overview'}  className={'about parallax pt-150 pb-150'} hasbtn={hasbtn}
        >
            <div className="fixed-bg ">
                <Img parallax src={imageSrc ? imageSrc : '/images/dynamic/home/video.jpg'} alt={''}/> :

            </div>

            <ThemeRow>
                <Col  sm={{span: 10}}>
                    {
                        title ?
                            <div className={'d-flex  mobile'}>
                                <Title text={title} textTransform={'none'} fontSize={40} lineHeight={48} fontWeight={500} center margin={'0 0 60px 0'} color={'white'} fontFamily={`"Avenir LT Pro 65 black"`}/>
                            </div> : ''
                    }

                </Col>
                <Col sm={{md: 2}}></Col>
                <Col sm={{offset: 5, span: 7}}>
                    <div className={'split-up para'}>
                        {ReactHtmlParser(desc)}
                    </div>
                    {
                        hasbtn ?
                            <div className={' mobile'}>
                                <ButtonV2 text={'Know more about us'} src={'/who-we-are'}
                                          margin={'40px 0px 0px 0'} borderColor={'#EDEEEF'} color={'#EDEEEF'}/>
                            </div> : ''
                    }
                </Col>
            </ThemeRow>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    position: relative;
    overflow: hidden;
    background-color: #EDEEEF;


    .fixed-bg {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;

        .bg {
            background-repeat: no-repeat;
            background-size: cover;
            background-attachment: fixed;
            width: 100%;
            height: 100%;
        }
    }

    .para {
        p {
            font-family: 'Aeonik';
            font-size: 18px;
            font-weight: 400;
            line-height: 26px;
        }
    }

    h2 {
        text-align: left;
        text-transform: none;
    }

    .shadow-right {
        position: absolute;
        top: 10px;
        right: -145px;
        height: fit-content;
    }

    .shadow-left {
        position: absolute;
        top: 0;
        bottom: 0px;
        margin: auto;
        height: fit-content;
        left: -40px;
    }

    p {
        color: white;
        font-size: 18px;
        line-height: 27px;
        font-weight: 400;
    }

    h5 {
        text-align: center;
        color: #EDEEEF;
        margin-bottom: 28px !important;

        @media (max-width: 767px) {
            text-align: start;
        }

        &:last-child {
            margin-bottom: 0px;
        }

    }

    .about__text {
        text-align: center;
    }

    @media (max-width: 1200px) {
        //.title {
        //  h2 {
        //    font-size: 90px;
        //    line-height: 90px;
        //  }
        //}
    }

    @media (max-width: 991px) {
        .about__text {
            min-width: 100%;
            margin: 0;
        }

        .col-sm-10, .col-sm-7.offset-sm-5 {
            flex: 0 0 100%;
            max-width: 100%;
            margin: 0;
        }

        .shadow-right {
            position: absolute;
            bottom: 0;
            right: 0;
            transform: translateX(75%);

            svg {
                width: 40%;
            }
        }

        .shadow-left {
            transform: translateX(-20%);
            left: 0;

            svg {
                width: 80%;
            }
        }
    }

    @media (max-width: 767px) {

        .mobile {
            justify-content: start !important;

            .dc-btn {
                margin: 40px 0 0 !important;
            }
        }

        p {
            text-align: start;
        }

        .about__text {
            h3 {
                font-size: 14px;
                line-height: 18px;
                margin-bottom: 20px;
            }

            p {
                width: 100%;
            }
        }

        .shadow-right {
            position: absolute;
            bottom: 0px;
            right: -20px;
            transform: none;
            //display: none;

            svg {
                //width: 40px;
                height: 100px;
            }
        }

        .shadow-left {
            transform: none;
            left: 0;
            bottom: 0px;
            top: auto;
            //display: none;

            svg {
                //width: 30px;
                height: 50px;
            }
        }

        .links {
            margin-top: 50px;
        }
    }


`;

export default MyComponent;
