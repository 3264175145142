import React from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {Img} from "../../Img";
import {medium} from "../../../styles/globalStyleVars";

const Overview = ({img, icon, title, subtitle,src,size, type}) => {
    return (
        <StyledOverview className='project-box'>
            <Link to={src}>
                <div className="project-box__single">
                    <div className="project-box__single__image ">
                        <Img alt={title} src={img ? img : '/images/static/blur.jpg'}/>
                    </div>
                    <div className="project-box__single__text">
                        <div className={'d-flex align-items-center justify-content-start'}>
                            <h6 className={'split-up'}>{subtitle}</h6>
                        </div>
                        <div className={'d-flex align-items-center justify-content-start'}>
                            <p>{title ? title : 'Magnolia'}</p>
                        </div>

                    </div>
                    {/*<div className="project-box__single__icon">*/}
                    {/*    <img src={icon ? icon : ''}/>*/}
                    {/*</div>*/}
                    <div className="project-box__single__category">
                        <p>{type ? type : ''}</p>
                    </div>
                </div>
            </Link>
        </StyledOverview>
    );
};

const StyledOverview = styled.div`
    overflow: hidden;
    position: relative;

    .project-box__single {

        position: relative;
        margin-bottom: 30px;

        a {
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 2;
            //cursor: pointer;
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
            opacity: 1; 
            transition: all 0.7s ease; 
            z-index: 11111;
        }


        &:hover {
            .global-image {
                transition: all 0.6s cubic-bezier(0.4, 0, 0, 1);
                transform: scale(1.06);
            }

            /* Show the tint on hover */

            &::before {
                background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
                opacity: 1;
            }

            .project-box__single__icon img {
                opacity: 1;
            }
            .project-box__single__text{
                bottom: 40px;
            }
        }

        &__image {
            position: relative;
            padding-top: calc(500 / 370 * 100%);
            overflow: hidden;

            .global-image {
                overflow: hidden;
                transition: all 0.6s cubic-bezier(0.4, 0, 0, 1);
            }


        }

        &__text {
            position: absolute;
            bottom: 30px;
            margin: auto;
            left: 0;
            right: 0;
            z-index: 11111;
            padding: 0 30px;
            transition: all 0.7s ease;

            p {
                font-size: 28px;
                line-height: 32px;
                color: #EDEEEF;
                text-transform: uppercase;
            }

            h6 {
                color: #EDEEEF;
                text-transform: uppercase;
            }
        }

        &__icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            //cursor: pointer;
            z-index: 999999;

            img {
                opacity: 0;
                transition: opacity 0.7s ease;

            }
        }

        &__category {

            background: #24717D;
            border-radius: 20px;
            padding: 8px 13px;
            color: white;
            position: absolute;
            top: 30px;
            left: 30px;

            p {
                color: white;
                font-size: 12px;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0.2px;
            }
        }
    }

    @media (max-width: 767px) {
        p {
            font-size: 24px;
            line-height: 32px;
        }
    }


`;

export default Overview;
