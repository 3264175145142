import React from 'react';
import {Img} from "../../Img";

const SliderImage = ({ src, title }) => {
    return (
        <div className="home-banner__slider__item">
            <Img src={src} alt={title} />
        </div>
    );
};

export default SliderImage;
