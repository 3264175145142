import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import Detail from "./details/detail";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchMediaDetail} from "../../api/redux/newsroom";
import {HelmetProvider} from "react-helmet-async";
import MetaTag from "../../components/meta/MetaTag";

const MyComponent = () => {

    const dispatch = useDispatch()
    const getData = useSelector(store => store.media)
    let {slug} = useParams();


    // api call
    useEffect(() => {
        let api_url = apiEndPoints.MEDIADETAIL
        dispatch(fetchMediaDetail([api_url + `/${slug}`]))
    }, [])


    //data refactor

    let detailData= getData?.detail?.page_data?.[0];
    let mediaList= getData?.detail?.list;
    let meta= getData?.detail?.page_data?.[0]?.meta;


    return (
        <HelmetProvider>
            <MetaTag title={detailData?.post_title ? detailData?.post_title + ' | Media Center | Brissie Estate Limited.' : 'Media Detail | Brissie Estate Limited.'} />
            <StyledComponent>
                <Detail data={detailData}/>
                {/*<MediaSlider data={mediaList}/>*/}
            </StyledComponent>

        </HelmetProvider>

    );
};
const StyledComponent = styled.section`
`
export default MyComponent;