import React, {useRef, useState} from 'react';
import styled from "styled-components";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, EffectFade, Navigation, Pagination} from "swiper";
import {Container} from "react-bootstrap";
import {hover} from "../../styles/globalStyleVars";
import useImageAndTextAnimationHome from "../../hooks/useImageAndTextAnimationHome";
import useSwiperAutoplayHome from "../../hooks/useSwiperAutoplayHome";
import SliderContent from "../../components/home/parts/SliderContent";
import SliderNavigation from "../../components/home/parts/SliderNavigation";
import SliderImage from "../../components/home/parts/SliderImage";

const MyComponent = ({data}) => {


    const [imageIndex, setImagerIndex] = useState(1)
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    const sliderChangeSpeed = 8000;
    const swiperRef = useRef(null);




    // handle image transition on change
    const handleSetTransition = (swiper, speed) => {
        // console.log('swiper',swiper);
        // console.log('speed',speed);
        for (let i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].style.transition = `${speed}ms`;
            swiper.slides[i]
                .querySelector(".global-image")
                .style.transition = `${speed}ms`;
        }
    };


    // handle slider progress
    const handleProgress = (swiper) => {
        let interleaveOffset = 0.5;
        for (let i = 0; i < swiper.slides.length; i++) {
            let slideProgress = swiper.slides[i].progress;
            let innerOffset = swiper.width * interleaveOffset;
            let innerTranslate = slideProgress * innerOffset;
            swiper.slides[i].querySelector(".global-image").style.transform =
                "translate3d(" + innerTranslate + "px, 0, 0)";
        }

    };

    // hand touch move not required this slider
    const handleTouchStart = (swiper) => {
        for (let i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].style.transition = "";
        }
    };

    const handleIndex = (e) => {
        setImagerIndex(e + 1)
    }

    useImageAndTextAnimationHome(data, imageIndex);
    useSwiperAutoplayHome(swiperRef, data);

    return (
        <StyledComponent imageIndex={imageIndex} className={'home-banner'} sliderChangeSpeed={sliderChangeSpeed}>
            {
                data && data?.length>0 &&
                <div className="home-banner__slider">
                    <Swiper
                        loop={true}
                        autoplay={true}
                        spaceBetween={30}
                        slidesPerView={1}
                        allowSlideNext={true}
                        allowSlidePrev={true}
                        allowTouchMove={false}
                        speed={1400}
                        onSetTransition={handleSetTransition}
                        onProgress={handleProgress}
                        touchStart={handleTouchStart}
                        grabCursor={false}
                        lazy={true}
                        watchSlidesProgress={true}
                        mousewheelControl={true}
                        keyboardControl={true}
                        pagination={{
                            clickable: true
                        }}
                        navigation={{
                            prevEl: '.banner-slider_prev',
                            nextEl: '.banner-slider_next ',
                        }}
                        modules={[Autoplay, Pagination, Navigation]}
                        onSlideChange={(e) => handleIndex(e.activeIndex)}
                    >
                        {
                            data && data.length>0 &&
                            data.map((element,index)=>{
                                return(
                                    <SwiperSlide id={index}>
                                        <SliderImage src={element?.image} title={element?.title} />
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                </div>
            }
            <div className="text-slider">
                {
                    data && data?.length>0 &&
                    <Swiper loop={false}
                            spaceBetween={30}
                            slidesPerView={1}
                            allowSlideNext={true}
                            allowSlidePrev={true}
                            allowTouchMove={false}
                            speed={600}
                            effect={"fade"}
                            modules={[Autoplay, Pagination, Navigation, EffectFade]}
                            navigation={{
                                prevEl: '.banner-slider_prev',
                                nextEl: '.banner-slider_next ',
                            }}
                    >
                        {
                            data && data.length>0 &&
                            data.map((element,index)=>{
                                return(
                                    <SwiperSlide id={index}>
                                        <SliderContent element={element}/>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                }

            </div>
            <Container>
                {
                    data && data?.length>0 &&
                    <SliderNavigation/>
                }
            </Container>

        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    height: 100vh;
    overflow: hidden;
    position: relative;
    background-color: #000;

    .banner-slider_prev, .banner-slider_next{
        cursor: pointer;
    }
    .home-banner__slider {
        height: 100vh;
        width: 100vw;
        position: relative;


        .swiper {
            position: absolute;
            height: 100%;
            width: 100%;
            z-index: 5;
            overflow: hidden;
        }

        .swiper-slide {
            overflow: hidden;

        }

        &__item {
            overflow: hidden;
            transform: scale(1.3);
            width: 100%;
            height: 100%;
            transition: all 2.5s ease;

            img {
                position: absolute;
                height: 100%;
                width: 100%;
                z-index: 5;
                filter: brightness(70%);
                transition: all .6s cubic-bezier(0.4, 0, 0, 1);
                top: 0;
                left: 0;
                object-fit: cover;
                right: 0;
                bottom: 0;

            }
        }
    }


    .container {
        position: absolute;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        margin: auto;
        left: 0;
        right: 0;
        bottom: 40px;
        .justify-content-start{
            gap: 15px;
            @media(max-width: 767px){
                gap: 15px;
                align-items: center;
                justify-content: center !important;
            }
        }
        span {
            transform: translateX(0);
            transition: transform 0.6s cubic-bezier(0.76, 0, 0.24, 1);
            z-index: 99999999 !important;
            width: 80px;
            @media (min-width: 768px) {
                &:first-child {
                    &:hover {
                        transform: translateX(-20px);
                    }
                }

                &:last-child {
                    &:hover {
                        transform: translateX(20px);
                    }
                }
            }

            @media (max-width: 767px) {
                display: flex;
                align-items: end;
            }

        }


        //svg{
        //  z-index: 999999999999999 !important;
        //}

        div {
            width: 100%;
        }
    }

    .banner-animation {
        height: 600px;
        width: 600px;

        z-index: 9;
        left: 0px;
        right: 70px;
        margin: auto;
        bottom: 0;
        top: 50px;

        svg {
            position: absolute;
            width: 100%;
            height: 100%;
            //height: 85%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 9;
            margin: auto;
            //background-color: red;
            transition: all .6s cubic-bezier(0.4, 0, 0, 1);

            image {
                //width: 1440px;
                //height: 782px;
                object-fit: cover;
                //display: none;
                transform: translateX(-50px);
                transition: transform .6s ease, visibility 0s, opaicty .6s ease;
                transform-origin: center;
                visibility: hidden;

                &:nth-of-type(${p => p.imageIndex}) {
                    visibility: visible;
                }

                &.active {
                    transform: none;
                }
            }

            #Mask-2 {
                //fill: #ffffff26
                fill: red;
            }
        }

        svg path#Shape-Edge {

        }
    }


    .text-slider {
        position: absolute;
        height: 80%;
        width: 80%;
        z-index: 99;
        inset: 0;
        margin: auto;

        .line {
            top: 0;
            bottom: -590px;
            left: 0;
            right: 0;
            margin: auto;
            z-index: 2;
            position: absolute;
            width: 60%;
            height: 10px;


            &:before {
                content: '';
                top: 0;
                bottom: 0px;
                left: 0;
                z-index: 2;
                position: absolute;
                width: 100%;
                height: 1px;
                opacity: 0.5;
                background-color: #ffffff;
            }

            &:after {
                content: '';
                width: 0;
                height: 1px;
                background-color: #ffffff;
                box-shadow: 0px 0px 15px #ffffff;
                    // background-color: ${hover};
                position: absolute;
                top: 0;
                bottom: 0px;
                left: 0;
                z-index: 3;
                opacity: 1;
                height: 3px;
                transition: width 2000ms cubic-bezier(0.4, 0, 0, 1);
                //transition: width 2000ms cubic-bezier(0.4, 0, 0, 1);
            }
        }


        .swiper {
            height: 100%;
            width: 100%;
            position: absolute;
        }

        &__content {
            position: absolute;
            height: fit-content;
            width: fit-content;
            inset: 0;
            margin: auto;
            z-index: 9;
            text-align: center;

            a {
                position: absolute;
                height: 100%;
                width: 100%;
                z-index: 22;
                left: 0;
                top: 0;

            }

            h1 {

                font-size: 60px;
                font-weight: 500;
                line-height: 68px;
                color: #ffffff;
                text-transform: uppercase;
                //margin-bottom: 20px;
                opacity: 0;
                //z-index: 999999999999;
                transform: translateY(30px);
                transition: .5s ease;
                transition-delay: 0s;
                
                @media(max-width: 767px){
                    font-size: 40px;
                    font-weight: 500;
                    line-height: 42px;
                }
            }


            h2 {

                font-size: 60px;
                font-weight: 500;
                line-height: 68px;
                color: #ffffff;
                text-transform: uppercase;
                //margin-bottom: 20px;
                opacity: 0;
                //z-index: 999999999999;
                transform: translateY(30px);
                transition: .5s ease;
                transition-delay: 0s;

                @media(max-width: 767px){
                    font-size: 40px;
                    font-weight: 500;
                    line-height: 42px;
                }
            }

            p {
                font-size: 24px;
                line-height: 32px;
                color: #ffffff;
                opacity: 0;
                transform: translateY(30px);
                transition: .5s ease;
                transition-delay: 0s;
            }

        }

        @media (max-width: 767px) {
            width: 100%;
            .line {
                width: 90% !important;
            }
        }

        .swiper-slide-active {
            .line {
                &:after {
                    width: 100% !important;
                }
            }
        }

    }

    .swiper-pagination {
        //display: none;
        bottom: 40px;
        @media (max-width: 767px) {
            bottom: 100px;
            width: 94%;
            display: flex;
            justify-content: center;
            align-items: center;
            left: 10px;
        }
    }

    .swiper-pagination-bullet {
        background-color: #fff;
        opacity: 1;
        height: 3px;
        padding: 0;
        border-radius: 5px;
        width: 100px;
        margin-right: -4px !important;
        border: 1px solid transparent;
        box-shadow: 0 0 15px #ffffff;

        //outline: none !important;
        @media (max-width: 600px) {
            width: 100%;
            &:last-child {
                margin-right: 0px !important;
            }


        }

        &.swiper-pagination-bullet-active {
            //width: 35px;
            background-color: #285854;
            opacity: 1;
            @media (max-width: 600px) {
                //width: 25px;
            }
        }
    }

    .swiper-slide-active {


        .home-banner__slider__item {
            transform: scale(1);
            //transition: 1.8s ease;
        }


        .text-slider__content {
            h1 {
                opacity: 1;
                transform: none;
                transition: .6s ease !important;
                font-weight: 900;

                &:hover{
                    letter-spacing: 6px;
                }
            }

            &.line:after {
                width: 100%;
            }

            p {
                opacity: 1;
                transform: none;
                //transition: .6s ease !important;
                transition-delay: .8s;
            }


        }

    }

    //on hover animation 

    .mouse-over {

        h1 {
            letter-spacing: 6px;
        }

        .home-banner__slider img {
            //transform: scale(1.05);
            z-index: 999999999;
            transition: .6s cubic-bezier(0.4, 0, 0, 1) !important;
        }


        .banner-animation {
            svg {
                opacity: 0.8;

                image {
                    transform: scale(.65) !important;
                    -moz-transform: scale(.65) !important;
                    z-index: 999999999 !important;
                }
            }
        }

        @media (min-width: 1800px) {
            .banner-animation {
                svg {
                    image {
                        transform: scale(.55);
                    }
                }
            }
        }
        @media (max-width: 767px) {
            .banner-animation {
                svg {
                    image {
                        transform: scale(0.70);
                    }
                }
            }
        }

    }

    @keyframes bouncing {
        0% {
            transform: translate3d(0, 0, 0);
        }
        100% {
            transform: translate3d(0, 10px, 0);
        }
    }

    @media (max-width: 767px) {
        .swiper-pagination{
            display: none;
        }
        .container {
            align-items: end;
            bottom: 40px;

            div {
                justify-content: start !important;
            }

        }

        .text-slider__content {
            text-align: center;
        }
    }

    @media (max-width: 650px) {
        .text-slider__content {
            width: calc(95% - 30px);
        }

        .banner-animation svg {
            width: 80%;
            height: 80%;
            margin: auto;
            overflow: hidden;
        }
    }
    @media (max-width: 615px) {

        .banner-animation {
            display: block;
        }

        .banner-animation svg {
            width: 80%;
            height: 80%;
            left: 0%;
            margin: auto;
            overflow: hidden;
        }

        .text-slider__content {
            h2 {
                font-size: 42px;
                line-height: 35px;
            }

            p {
                font-size: 20px;
                line-height: 25px;
            }
        }

        .go-down {
            left: 15px;
        }

    }



`;

export default MyComponent;
