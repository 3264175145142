import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Title from "../../components/Title";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Pagination,A11y, Grid} from "swiper";
import ReactHtmlParser from "react-html-parser";
import {black} from "../../styles/globalStyleVars";
import reactHtmlParser from "react-html-parser";
import ThemeRow from "../../theme/row";
import WhyUsSingle from "../../components/home/parts/WhyUsSingle";

const MyComponent = ({data}) => {
    return (
        <StyledComponent className={'make-best '}>
            <div className="make-best__inner">
                <ThemeRow rowClass={'justify-content-center align-items-start'}>
                    <Col md={5} >
                        <Title text={data?.title} fontSize={48} fontWeight={500} lineHeight={56} margin={'0 0 40px 0'} color={'#285854'} fontFamily={`${black}`}/>
                        <p>{reactHtmlParser(data?.description)}</p>
                    </Col>
                    <Col md={{span: 6, offset: 1}} className={'slider-wrapper-why'}>

                        {
                            data?.list && data?.list?.length>0 &&
                            <Swiper
                                loop={false}
                                spaceBetween={30}
                                slidesPerView={2}
                                grid={{
                                    fill: 'row',
                                    rows: 2
                                }}
                                allowSlideNext={true}
                                allowSlidePrev={true}
                                allowTouchMove={true}
                                speed={900}
                                pagination={{
                                    clickable: true
                                }}
                                navigation={{
                                    prevEl: '.slider_prev',
                                    nextEl: '.slider_next',
                                }}
                                modules={[Navigation, Pagination,A11y, Grid]}
                            >

                                {
                                    data?.list && data?.list?.length>0 &&
                                    data?.list?.map((element,index)=>{
                                        return(
                                            <SwiperSlide key={index}>
                                                <WhyUsSingle index={index} element={element}/>
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>
                        }

                    </Col>
                </ThemeRow>
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    position: relative;


    p {
        color: #000000;
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
    }

    .make-best__inner {
        position: relative;

        .container {
            padding-top: 150px;
            padding-bottom: 150px;
            @media(max-width: 767px){
                padding-top: 80px;
                padding-bottom: 80px; 
            }
        }
    }
    .swiper-grid > .swiper-wrapper{
        padding-bottom: 40px;
    }
    .swiper-pagination {
        top: unset !important;
        bottom: 0 !important;
         
        .swiper-pagination-bullet.swiper-pagination-bullet-active{
            background: rgba(40, 88, 84, 1); 
        }
        .swiper-pagination-bullet {
            background: rgba(40, 88, 84, 0.25);
            border-radius: 5px;
        }
    }

    .swiper-slide.swiper-slide-active{
        //.make-best__slider{
        //    background-color: #285854;
        //
        //    img{
        //        filter: invert(1);
        //    }
        //    p{
        //        color: white;
        //    }
        //}
    }


    .make-best__slider {
        transition: 0.7s all ease;
        cursor: pointer;
        &:hover {
            background-color: #285854;

            img {
                filter: invert(1);
            }

            p {
                color: white;
            }
        }
    }
    .make-best__slider {
        background-color: #F2F2F2;

        &__content {
            padding-top: calc(250 / 270 * 100%);
            position: relative;
            
            @media(max-width: 767px){
                padding-top: 125%;
            }

            img {
                position: absolute;
                top: 20px;
                left: 20px;
                transition: 0.7s all ease;
                height: 50px;
                width: 50px;
                
            }

            p {
                position: absolute;
                bottom: 20px;
                left: 20px;
                right: 20px;
                font-size: 20px;
                line-height: 24px;
                font-weight: 500;
                color: #000000;
                transition: 0.7s all ease;
                
            }
        }
        
        transition: 0.7s all ease;
        
        &.first{
            background-color: #285854;
            
            img{
                filter: invert(1);
            }
            p{
                color: white;
            }
        }
    }


    .swiper-pagination-bullet {
        background-color: #fff;
        opacity: .5;
        height: 5px;
        padding: 0;
        border-radius: 2px;
        width: 20px;
        margin-right: 20px !important;
        @media (max-width: 600px) {
            width: 15px;
            margin-right: 10px !important;
        }

        &.swiper-pagination-bullet-active {
            width: 35px;
            opacity: 1;
            @media (max-width: 600px) {
                width: 25px;
            }
        }
    }

    @media(max-width: 992px){
        .col-md-5, .slider-wrapper-why{
            margin: 0;
            flex: 0 0 100%;
            max-width: 100%;
        }
        .slider-wrapper-why{
            margin-top: 60px;
        }
    }

`;

export default MyComponent;
