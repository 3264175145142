import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollToTop = (dependencies) => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname, ...dependencies]);
};

export default useScrollToTop;
