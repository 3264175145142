import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {grayText, white} from "../../styles/globalStyleVars";
import Mision from "../../components/about/parts/Mision";

const MyComponent = ({data, bgcolor, zIndex}) => {


    console.log(data, 'mission')


    return (
        <StyledComponent className={'about'}  zIndex={zIndex}  bgcolor={bgcolor}>
            <Container>
                <div className={'bg-noise'}/>
                <Row>
                    <Col md={{span:5,offset:1}}>
                        <div className={'about__vision first'}>
                           <Mision data={data} title={data?.mtitle} description={data?.mdescription}/>
                        </div>
                    </Col>
                    <Col md={{span:5}}>
                        <div className={'about__vision'}>
                            <Mision data={data} title={data?.vtitle} description={data?.vdescription}/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};
const StyledComponent = styled.section`
    position: relative;
    overflow: hidden;
    background-color: ${props => props.bgcolor ? props.bgcolor : `#EDEEEF`};
    z-index: ${props => props.zIndex ? props.zIndex : -2};

    .col-md-5 {
        &:first-child {
            padding-left: 0px !important;
            padding-right: 0px !important;
        }
    }


    //.row{
    //  margin-left: -30px !important;
    //}

    &:after {
        height: 50%;
        position: absolute;
        bottom: 0;
        //top: 0;
        left: 0;
        right: 0;
        background: #FFFFFF;
        content: "";
            // display: ${props => props.remove ? 'none' : 'block'};
            // opacity: ${props => props.remove ? '0' : '1'};
        z-index: -1;
    }


    .container {
        position: relative;

        .bg-noise {
            position: absolute;
            inset: 0px;
            overflow: hidden;
            width: 100%;
            height: 100%;
            background: #285854;

            //  &:after{
            //  content: "";
            //  background-image: url('/images/static/noise.svg');
            //  background-blend-mode: multiply;
            //  width: 100%;
            //  height: 100%;
            //  position: absolute;
            //  inset: 0px;
            //  opacity: 1
            //}
        }
    }


    h4 {
        margin-bottom: 30px;
        color: ${white};
        font-size: 32px;
        font-weight: 400;
        line-height: 56px;
        font-family: 'Gustavo';
    }

    p {
        color: ${white};
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        font-family: 'Aeonik';
        letter-spacing: unset;
    }

    .about {
        &__vision {

            padding-top: 120px;
            padding-bottom: 120px;

            &.first {
                margin-right: 20px;
            }
        }
    }

    @media (max-width: 992px) {
    
        
        .col-md-5.offset-md-1 {
            .about {
                &__vision {
                    padding-top: 80px;
                    padding-bottom: 80px;
                }
            }
        }

        .col-md-5 {
            .about {
                &__vision {
                    padding-top: 0;
                    padding-bottom: 80px;
                }
            }
        }

        .col-md-5.offset-md-1, .col-md-5 {
            flex: 0 0 100%;
            max-width: 100%;
            margin: 0;
            padding-left: 40px !important;
            padding-right: 40px !important;
        }
    }
    @media (max-width: 767px) {

        .col-md-5.offset-md-1 {
            .about {
                &__vision {
                    padding-top: 80px;
                    padding-bottom: 80px;
                }
            }
        }

        .col-md-5 {
            .about {
                &__vision {
                    padding-top: 0;
                    padding-bottom: 80px;
                }
            }
        }

        .col-md-5.offset-md-1, .col-md-5 {
            flex: 0 0 100%;
            max-width: 100%;
            margin: 0;
            padding-left: 15px !important;
            padding-right: 15px !important;
        }
    }
}
`
export default MyComponent;