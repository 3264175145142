import React, {useEffect, useState} from "react";
import gsap from "gsap";
import styled from "styled-components";
import {useLocation} from "react-router-dom";
import {hover} from "../styles/globalStyleVars"; // Import your CSS file

const Cursor = () => {



    return (
        <StyledComponent>
            <div className="cursor">
                {/*<div className="cursor__ball cursor__ball--big ">*/}
                {/*    <svg height="30" width="30">*/}
                {/*        <circle cx="15" cy="15" r="12" stroke-width="2" stroke={`#f7f8fa`}></circle>*/}
                {/*    </svg>*/}
                {/*</div>*/}
                <div className="cursor__ball cursor__ball--small">
                    <svg height="30" width="30">
                        <circle cx="15" cy="15" r="12" strokeWidth="2" stroke="#f7f8fa" fill="none"></circle>
                    </svg>
                </div>
            </div>
        </StyledComponent>
    );
};
const StyledComponent = styled.section`
  .cursor {
    pointer-events: none;
    &__ball {
      position: fixed;
      top: 0;
      left: 0;
      mix-blend-mode: difference;
      z-index: 999999999999;

      circle {
        fill: transparent;
        //fill: #f7f8fa;
      }
    }

    &__ball-big {
      position: relative;

      
      .piu {
        opacity: 0;
        visibility: hidden;
        height: 0;
      }

      .piu.active {
        font-size: 2px;
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        letter-spacing: 0;
        opacity: 1;
        visibility: visible;
        height: auto;
      }
    }


  }

  .cursor__ball--big.active {
    opacity: 0.8;

    svg {
      //transform: scale(3);
      fill: #f7f8fa;
    }
  }


  .cursor__ball--small.active {
    opacity: 0.3;

    svg {
      transform: scale(0.5);
    }
  }

  @media (max-width: 767px) {
    display: none;
  }
`;
export default React.memo(Cursor);
