import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useParams,} from "react-router-dom";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchPostDetail} from "../../api/redux/project";
import {useDispatch, useSelector} from "react-redux";
import {HelmetProvider} from 'react-helmet-async'
import Overview from "./details/ProjectOverview";
import InnerBanner from "./details/InnerBanner";
import Video from "../../components/Video";
import Gallery from "./details/Gallery";
import ProjectForm from "./details/ProjectForm";
import FeatureSlider from "./details/FeatureSlider";
import Glance from "./details/Glance";
import MetaTag from "../../components/meta/MetaTag";
import ThemeContainer from "../../theme/container";
import ButtonV2 from "../../components/ButtonV2";
import {hoverSecond} from "../../styles/globalStyleVars";
import PopupForm from "../../components/PopupForm";

const MyComponent = () => {

    const dispatch = useDispatch()
    let {slug} = useParams();

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.PROJECTDETAIL
        dispatch(fetchPostDetail([api_url + `/${slug}`]))
    }, [])

    let getPost = useSelector(state => state.project)


    // data refactor

    let banner=getPost?.detail?.page_data?.[0]?.banner;
    let overview=getPost?.detail?.page_data?.[0]?.overview;
    let glance=getPost?.detail?.page_data?.[0]?.at_a_glance;
    let feature=getPost?.detail?.page_data?.[0]?.feature;
    let video=getPost?.detail?.page_data?.[0]?.video;
    let gallery=getPost?.detail?.page_data?.[0]?.gallery;
    let map=getPost?.detail?.page_data?.[0]?.map;
    let form=getPost?.detail?.page_data?.[0]?.form;
    let related_project=getPost?.detail?.projects;


    const [show, setShow] = useState(false);
    const [popupId, setPopupId] = useState()

    const handleClose = () => setShow(false);
    const handleShow = (e) => {
        setShow(true)
        setPopupId(e)
    }


    return (

        <HelmetProvider>

            <MetaTag title={getPost?.detail?.page_data?.[0]?.post_title + ' | Pojects | Brissie Estate Limited.'}/>
            <StyledComponent>
                <InnerBanner
                    title={getPost?.detail?.page_data?.[0]?.post_title} subtext={'News & Events'}
                    img={banner?.image ? banner?.image : '/images/static/blur.jpg'}
                    mimg={banner?.mimage}
                    location={banner?.project_location}
                    type={banner?.project_type}
                    size={banner?.size}
                    icon={banner?.icon}
                />

                {
                    overview?.description &&
                    <Overview file={overview?.brochure_file} project_name={getPost?.detail?.page_data?.[0]?.post_title} desc={overview?.description} title={overview?.title} hasbtn bg={overview?.bg_texture} pdf={overview?.brochure_file}/>
                }


                {
                    <Glance data={glance}/>
                }

                {
                    feature?.list?.length>0 &&
                    <FeatureSlider data={feature}/>
                }
                {
                    video?.image &&
                    <Video fromDetail data={video}/>
                }


                {
                    gallery?.list.length > 0 &&
                    <Gallery data={gallery}/>
                }

                <ProjectForm id={'request-callback'} data={form} iframe={map?.url}/>
                {/*<RelatedProjects data={related_project}/>*/}


                {
                    overview?.brochure_file && !overview?.description &&
                    <section className="brochure_download ">
                        <div className={'bg-noise'}/>
                        <ThemeContainer colProps={{md: 12}} containerClass={'d-flex justify-content-center align-items-center'}>
                            <ButtonV2 color={'#fff'} borderColor={'#fff'} text={'DOWNLOAD BROCHURE'} margin={'0'} download file={overview?.brochure_file} target={'_blank'}/> : ''

                            {/*<div onClick={() => handleShow(true)}>*/}
                            {/*    <ButtonV2 color={'#fff'} borderColor={'#fff'} text={'DOWNLOAD BROCHURE'} margin={'0'} />*/}
                            {/*</div>*/}
                            : ''
                        </ThemeContainer>
                    </section>
                }
                <PopupForm project_name={getPost?.detail?.page_data?.[0]?.post_title} show={show} data={overview?.brochure_file} handleClose={handleClose}/>

            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`

    .brochure_download {
        position: relative;
        overflow: hidden;
        background: ${hoverSecond};
        padding: 90px 0;
        background-blend-mode: multiply;

        .bg-noise {
            position: absolute;
            inset: 0;
            overflow: hidden;
            width: 100%;
            height: 100%;
            background-blend-mode: multiply;

            &:after {
                content: "";
                background-image: url('/images/static/contact_bg.jpg');
                background-blend-mode: multiply;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: left top;
                width: 100%;
                height: 100%;
                position: absolute;
                inset: 0;
                opacity: 0.5;
            }
        }
    }

`;

export default MyComponent;
