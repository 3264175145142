import React from 'react';
import ReactHtmlParser from 'react-html-parser';

const WhyUsSingle = ({ index, element }) => {
    return (
        <div className={`make-best__slider ${index === 0 ? '' : null}`}>
            <div className="make-best__slider__content">
                <img src={element?.logo} alt={element?.subtitle}/>
                <p>{ReactHtmlParser(element?.subtitle)}</p>
            </div>
        </div>
    );
}

export default WhyUsSingle;
