import React, { Component, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import HtmlParser from 'react-html-parser';
import ReactHtmlParser from "react-html-parser";
import {hover, text} from "../../styles/globalStyleVars";

const Address = ({ top, posts,data }) => {

    return (
        <StyleAddress top={top} className={''}>
            <div className={'bg-noise'}/>
            <Container>
                <Row>
                    <Col sm={6} className='contact-address '>
                        <div className="contact-address__top">
                            <p className={'split-up'}>Corporate Office</p>
                            {data?.title && <h4 className={'split-up'}>
                                <a href="https://www.google.com/maps" target={'_blank'}>
                                    {ReactHtmlParser(data?.title)}
                                </a>
                            </h4>}
                        </div>
                    </Col>

                    <Col sm={6} className='contact-address d-flex justify-content-between flex-wrap'>
                        <div className="contact-address__bottom">
                            {
                                data?.hotline &&
                                <div className="contact-address__bottom__single">
                                    <p className={'split-up'}>Hotline</p>
                                    {data?.hotline && <h4 className={'split-up'}><a href={`tel:${data?.hotline}`}>{data?.hotline}</a></h4>}
                                </div>
                            }
                        </div>

                        <div className="contact-address__bottom last">
                            {
                                data?.email &&
                                <div className="contact-address__bottom__single">
                                    <p className={'split-up'}>EMAIL</p>
                                    {data?.email && <h4 className={'split-up'}><a className={'email'} href={`mailto:${data?.email}`}>{data?.email}</a></h4>}
                                </div>
                            }
                        </div>

                    </Col>
                </Row>
            </Container>
        </StyleAddress>
    )
}
const StyleAddress = styled.section`
    background-color: #EDEEEF;
    position: relative;
    padding: 100px 0;

    

    .bg-noise {
        position: absolute;
        inset: 0;
        overflow: hidden;
        width: 100%;
        height: 100%;
        background-color: #FFFFFF;

        &:after {
            content: "";
            background-image: url('/noise-new.svg');
            background-blend-mode: multiply;
            background-size: cover;
            background-repeat: no-repeat;
            width: 100%;
            height: 100%;
            position: absolute;
            inset: 0;
            opacity: 1
        }
    }


    &:after {
        content: "";
        width: 80%;
        top: ${props => props.top || '0'};
        bottom: 0;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
    }

    .contact-map {
        &__img {
            padding-top: calc(580 / 470 * 100%);
            position: relative;
            height: 100%;
            z-index: 1;

            a {
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                z-index: 2;
            }

            img {
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                object-fit: cover;
            }
        }
    }

    .contact-address {
        position: relative;
        z-index: 2;

        &__top {

            h4 {
                //font-size: 50px;
                //font-weight: bold;
                //line-height: 50px;
                color: #000000;
                text-transform: capitalize;
                margin: 0;
                font-size: 18px;
                font-weight: 400;
                line-height: 27px;
            }

            p {
                color: rgba(86, 87, 90, 0.5);
                text-transform: uppercase;
                font-family: 'Gustavo';
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 1.4px;
                margin: 0 0 10px;
            }

        }

        &__bottom {
            display: flex;
            justify-content: space-between;

            &__single {
                &:last-child {


                    h4 {
                        a {
                            color: #000 !important;

                            &:hover {
                                color: ${hover} !important;
                            }

                            &.email{
                                text-transform: none !important;
                            }
                        }
                    }

                }

                p {
                    color: rgba(86, 87, 90, 0.5);
                    text-transform: uppercase;
                    font-family: 'Gustavo';
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    letter-spacing: 1.4px;
                    margin: 0 0 10px;

                }

                a:not(.link a) {
                    font-size: 20px;
                    font-weight: bold;
                    color: ${hover};
                    line-height: 25px;
                    display: block;

                    &.mail {
                        margin-top: 53px;
                        color: #56575A;
                    }

                    //&:hover {
                    //  text-decoration: underline;
                    //}
                }

                .link {
                    margin-top: 50px;
                }
            }
        }


        h4 {
            color: #000000;
            text-transform: capitalize;
            margin: 0;
            font-size: 18px;
            font-weight: 400;
            line-height: 27px;

            a {
                color: #000000 !important;
                text-transform: capitalize !important;
                margin: 0 !important;
                font-size: 18px !important;
                font-weight: 400 !important;
                line-height: 27px !important;
            }
        }

        p {
            color: rgba(86, 87, 90, 0.5);
            text-transform: uppercase;
            font-family: 'Gustavo';
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 1.4px;
            margin: 0 0 10px;

        }

    }

    @media (max-width: 769px) {
        //padding-bottom: 75px;
        .contact-map {
            flex: 0 0 100%;
            max-width: 100%;
        }

        .contact-address {
            flex: 0 0 100%;
            max-width: 100%;
            margin-left: 0;
            //margin-top: 50px;

            &__top {
                margin-bottom: 30px;

                h2 {
                    font-size: 30px;
                    line-height: 35px;
                }
            }

            &__bottom {
                margin-bottom: 30px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

    }

`;
export default Address;
