import { useEffect } from 'react';

const useSwiperAutoplayHome = (swiperRef, data, delay = 5000) => {
    useEffect(() => {
        const swiper = swiperRef.current;
        const autoplayTimeout = setTimeout(() => {
            if (swiper && swiper.autoplay) {
                swiper.autoplay.start();
            }
        }, delay);

        return () => {
            clearTimeout(autoplayTimeout);
        };
    }, [data, swiperRef, delay]);
};

export default useSwiperAutoplayHome;
