import {Route, Switch, useHistory, useLocation} from 'react-router-dom';
import Error from './pages/404';
import GlobalStyle from "./styles/globalStyle";
import {Parallax, ParallaxImg} from "./components/animations/Parallax";
import {CSSPlugin, gsap} from "gsap";
import {ScrollSmoother} from "gsap/ScrollSmoother";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {SplitText} from "gsap/SplitText";
// page imports
import About from './pages/about';
import Components from './pages/components';
import Home from './pages/home';
import Project from './pages/project';
import ProjectDetail from './pages/project/single';
import Contact from './pages/contact';
import {SplitUp} from "./components/animations/TextAnimation";
import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import PageTransition from "./components/animations/PageTransition";
import Footer from "./components/Footer";
import landowner from "./pages/landowner";
import Buyer from "./pages/buyer";
import media from "./pages/media";
import detail from "./pages/media/single";
import Menu from "./components/Menu";
import {ToastContainer} from "react-toastify";
import RightMessenger from "./components/RightMessenger";
import CustomCursor from "./components/CustomCursor";
import {useSelector} from "react-redux";
import {Loading} from "./components/Loading";


// import css
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'react-toastify/dist/ReactToastify.css';
import 'swiper/swiper-bundle.css';
import {enableBodyScroll} from "body-scroll-lock"; // Import Swiper styles



function App() {

    const location = useLocation();
    gsap.registerPlugin(ScrollTrigger, ScrollSmoother, CSSPlugin, SplitText);
    const store = useSelector(store => store)

    const [offset, setOffset] = useState(0)

    useEffect(() => {
        setOffset(Number(localStorage.getItem('containeroffset')))
    }, [])

    const history = useHistory();



    // smooth scroll init
    const el = useRef();


    //To get the first & second slug
    const pathSegments = location.pathname.split("/");
    const firstSegment = pathSegments[1];


    //Handle the sticky bar for project-page and landowner-page

    const [isProjectPage, setIsProjectPage] = useState(false);
    const [isLandowner, setIsLandowner] = useState(false);

    useEffect(() => {

        if(firstSegment===`project`) {
            setIsProjectPage(true)
        }
        else setIsProjectPage(false)
        if(location?.pathname==='/landowner') {
            setIsLandowner(true)
        }
        else  setIsLandowner(false)
    }, [location?.pathname]);



    // smooth scroll
    let smoother;
    useEffect(() => {
        smoother = ScrollSmoother.create({
            smooth: 2, // how long (in seconds) it takes to "catch up" to the native scroll position
            effects: true, // looks for data-speed and data-lag attributes on elements
            // speed: 2,
        });

        //Cursor Movement
        const smallBall = document.querySelector(".cursor__ball--small");
        const hoverables = document.querySelectorAll(".hoverable");
        const alla = document.querySelectorAll("a");

// Initialize cursor position
        let cursorX = 0;
        let cursorY = 0;

// Listeners
        document.body.addEventListener("mousemove", onMouseMove);
        hoverables.forEach((element) => {
            element.addEventListener("mouseenter", onMouseHover);
            element.addEventListener("mouseleave", onMouseHoverOut);
        });
        alla.forEach((element) => {
            element.addEventListener("mouseenter", onMouseHover);
            element.addEventListener("mouseleave", onMouseHoverOut);
        });

// Move the cursor
        function onMouseMove(e) {
            cursorX = e.clientX - 15;
            cursorY = e.clientY - 15;

            requestAnimationFrame(updateCursorPosition);
        }

// Update cursor position using GSAP
        function updateCursorPosition() {
            gsap.to(smallBall, { x: cursorX , y: cursorY , duration: 0.5 });
        }

// Hover over an element
        function onMouseHover() {
            const smallBallCircle = document.querySelector(".cursor__ball--small circle");
            gsap.to(smallBallCircle, {
                scale: 0.7,
                fill: "#f7f8fa",
                strokeWidth: 0,
                duration: 0.3,
            });
        }

// Hover out of an element
        function onMouseHoverOut() {
            const smallBallCircle = document.querySelector(".cursor__ball--small circle");
            gsap.to(smallBallCircle, {
                scale: 1,
                duration: 0.3,
                stroke: 'white',
                strokeWidth: 2,
                fill: 'transparent',
            });
        }

        if (location.hash) {
            setTimeout(() => {
                smoother.scrollTo(location.hash, 3);
            }, 800);
        } else {
            // Check if it's not the '/projects' page
            if (location.pathname !== '/projects') {
                setTimeout(() => {
                    smoother.scrollTo(0, 0);
                }, 800);
            }
        }



        // ScrollTrigger.refresh();


     }, [location, location.pathname,location.hash])


    // route change scroll top & page class
    useEffect(() => {
        if (location.pathname === '/') {
            document.body.classList.add('in-home-page')
        } else {
            document.body.classList.remove('in-home-page')
        }
        let tl = gsap.timeline()
        let getMenu = document.querySelector('.slide-menu');

        if (window.innerWidth > 600) {
            setTimeout(() => {
                enableBodyScroll('body')
            }, 300)
        }
        tl.to('.slide-menu li,.accordion-item ', {
            y: 20,
            opacity: 0,
            ease: 'Power4.easeInOut',
            stagger: .01,
            duration: .4
        }).to(getMenu, {
            x: '100%',
            ease: 'Power4.easeInOut',

        }, '-=.3').to(getMenu, {
            display: 'none',
            duration: 0,
            ease: 'Power4.easeInOut',

        }).to('.menu-overlay', {
            display: 'none',
            opacity: 0,
            ease: 'Power4.easeInOut',

        }, '-=.3')
        document.body.classList.remove('menu-open')
    }, [location?.pathname,history?.location])

    // animation functions
    // ImageReveal()
    Parallax()
    ParallaxImg()
    SplitUp()

   // page transition
    PageTransition()

    return (
        <>
            <GlobalStyle/>
            <ToastContainer
                position="top-right" autoClose={4000} closeOnClick hideProgressBar={true}/>

            {/*page transition */}
            <div className="page-loader">
                <img src="/images/static/logo.svg" alt=""/>
            </div>
            <Menu/>
            {/*{*/}
            {/*    window?.innerWidth>767 &&*/}
            {/*    <LeftFloatingIcon/>*/}
            {/*}*/}

            <RightMessenger/>
            {/*<RightFloatingIcon/>*/}
            {/*<CustomCursor/>*/}
            {store.project?.loading && <Loading/>}
            {/*{store.home?.formLoading && <Loading/>}*/}

            <div className="App" ref={el} id="smooth-wrapper">


                <div id="smooth-content">
                    {/*<ScrollToTop/>*/}
                    <Switch location={location} key={'location.pathname'}>
                        <Route exact path="/" component={Home}/>
                        <Route exact path="/who-we-are" component={About}/>
                        <Route exact path={`/projects`} component={Project}/>
                        <Route exact path={`/project/:slug`} component={ProjectDetail}/>
                        <Route exact path={`/landowner`} component={landowner}/>
                        <Route exact path={`/buyer`} component={Buyer}/>
                        <Route exact path={`/contact`} component={Contact}/>
                        <Route exact path={`/media-center`} component={media}/>
                        <Route exact path={`/media-center/:slug`} component={detail}/>
                        <Route exact path="/components" component={Components}/>
                        <Route component={Error}/>
                    </Switch>
                    <Footer />
                </div>
            </div>
        </>


    );
}

export default App;
