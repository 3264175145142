import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {hover, text, title} from "../styles/globalStyleVars";
// import Logo from '../public/images/static/logo.svg';
import {components} from "react-select";
// import arrow from "../public/images/static/arrow.svg";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";

const MyComponent = ({footerTop, footerTopBg}) => {

    const conteinerRef = useRef()
    const dispatch = useDispatch()
    const reasonQRef = useRef();

    const options = [
        {value: 'Buy Property', label: 'Buy Property'},
        {value: 'Joint Venture', label: 'Joint Venture'},
        {value: 'Investment', label: 'Investment'},
        {value: 'Land Sell', label: 'Land Sell'},
        // {value: 'Career', label: 'Career'}
    ]

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            borderRadius: 0,
            color: state.isSelected ? '#FFF' : '#221F1F',
            backgroundColor: state.isSelected ? '#191818' : '#FFF',
            margin: 0,
            fontSize: 12,
            // cursor: 'pointer',
            paddingLeft: 25
        }), menu: (provided, state) => ({
            ...provided,
            color: '#FFF',
            backgroundColor: state.isSelected ? '#191818' : 'rgba(255,255,255,0)',
            margin: 0,
            borderRadius: 5,
            fontSize: 12,

        }), menuList: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#191818' : '#FFF',
            borderRadius: 0,
            fontWeight: '600',
            color: '#FFF',
            fontSize: 12,

        }),

    };


    // disable scroll on input
    useEffect(() => {
        var inputTypeNumbers = document.querySelectorAll("input[type=number]");
        for (var a = 0; a < inputTypeNumbers.length; a++) {
            inputTypeNumbers[a].onwheel = function (event) {
                event.target.blur();
            };
        }
    }, [])

    //--- form submit
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });


    // form submit
    const [reg, setReg] = useState({
        name: "",
        department: "",
        phone: "",
        email: "",
        reason: '',
        msg: "",
    });

    const handleQReason = (e) => {
        setReg({...reg, ['reason']: e})
    }





    const DropdownIndicator = (props) => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <img src={'arrow'} alt="arrow"/>
                </components.DropdownIndicator>
            )
        );
    };


    return (
        <>
            <Footer footerTopBg={footerTopBg} className={'footer '}>
                <div className={'bg-noise'}/>
                <Container>
                    <Row className={'footer__big-number justify-content-center'}>
                        <Col md={12} className={'d-flex flex-wrap flex-column align-items-center justify-content-center'}>
                            <a style={{width: 'auto'}} className={'big-number '} href="tel:096 1382 1313">096 1382 1313</a>
                            <a style={{width: 'auto'}} className={'email'} href="mailto:brissieestate@gmail.com">brissieestate@gmail.com</a>
                        </Col>
                        <Col md={12} className={'d-flex flex-wrap justify-content-center'}>
                            <div className={'footer__social footer-social'}>
                                <ul>
                                    <li>
                                        <a target={'_blank'} rel="nofollow" href="https://www.facebook.com/brissie.estate/"
                                           className={'hover'}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="4.877" height="10.466" viewBox="0 0 4.877 10.466">
                                                <path id="Path_26" data-name="Path 26" d="M1012.361,3918.425v-.9a1.965,1.965,0,0,1,.019-.315.612.612,0,0,1,.086-.219.351.351,0,0,1,.216-.149,1.551,1.551,0,0,1,.4-.041h.9V3915h-1.441a2.344,2.344,0,0,0-1.8.594,2.5,2.5,0,0,0-.546,1.75v1.086h-1.08v1.8h1.079v5.233h2.166v-5.233h1.442l.19-1.8Z" transform="translate(-1009.116 -3914.995)" fill="#56575a"/>
                                            </svg>
                                        </a>
                                    </li>

                                    {/*<li>*/}
                                    {/*    <a target={'_blank'} href={'https://www.linkedin.com/company/Brissiedevelopments/?originalSubdomain=bd'}*/}
                                    {/*       className={'hover'}>*/}
                                    {/*        <svg id="layer1" xmlns="http://www.w3.org/2000/svg" width="11.035" height="10.328" viewBox="0 0 11.035 10.328">*/}
                                    {/*            <path id="path1009" d="M281.527,167.31l4.26,5.7-4.287,4.632h.965l3.754-4.055,3.033,4.055h3.284l-4.5-6.017,3.991-4.311h-.965l-3.457,3.735-2.793-3.735Zm1.419.711h1.509l6.661,8.907h-1.509Z" transform="translate(-281.5 -167.31)" fill="#56575a"/>*/}
                                    {/*        </svg>*/}
                                    {/*    </a>*/}
                                    {/*</li>*/}
                                    {/*<li>*/}
                                    {/*    <a href="https://www.instagram.com/rancondevelopments/" target={'_blank'} className={'hover'}>*/}
                                    {/*        <svg xmlns="http://www.w3.org/2000/svg" width="11.186" height="11.186" viewBox="0 0 11.186 11.186">*/}
                                    {/*            <g id="Group_13724" data-name="Group 13724" transform="translate(-2167 -9029)">*/}
                                    {/*                <path id="Path_5912" data-name="Path 5912" d="M5.593,1.008c1.493,0,1.67.006,2.26.033a3.093,3.093,0,0,1,1.038.193,1.733,1.733,0,0,1,.643.418,1.733,1.733,0,0,1,.418.643,3.1,3.1,0,0,1,.193,1.038c.027.59.033.767.033,2.26s-.006,1.67-.033,2.26a3.1,3.1,0,0,1-.193,1.038A1.852,1.852,0,0,1,8.891,9.953a3.093,3.093,0,0,1-1.038.193c-.59.027-.767.033-2.26.033s-1.67-.006-2.26-.033a3.093,3.093,0,0,1-1.038-.193,1.733,1.733,0,0,1-.643-.418,1.733,1.733,0,0,1-.418-.643A3.093,3.093,0,0,1,1.04,7.853c-.027-.59-.033-.767-.033-2.26s.006-1.67.033-2.26a3.093,3.093,0,0,1,.193-1.038,1.733,1.733,0,0,1,.418-.643,1.733,1.733,0,0,1,.643-.418A3.093,3.093,0,0,1,3.333,1.04c.59-.027.767-.033,2.26-.033M5.593,0C4.074,0,3.883.006,3.287.034a4.106,4.106,0,0,0-1.358.26A2.741,2.741,0,0,0,.939.939a2.741,2.741,0,0,0-.645.991,4.106,4.106,0,0,0-.26,1.358C.006,3.883,0,4.074,0,5.593S.006,7.3.034,7.9a4.106,4.106,0,0,0,.26,1.358,2.741,2.741,0,0,0,.645.991,2.743,2.743,0,0,0,.991.645,4.106,4.106,0,0,0,1.358.26c.6.027.787.034,2.306.034S7.3,11.179,7.9,11.152a4.106,4.106,0,0,0,1.358-.26,2.86,2.86,0,0,0,1.636-1.636,4.106,4.106,0,0,0,.26-1.358c.027-.6.034-.787.034-2.306s-.006-1.709-.034-2.306a4.106,4.106,0,0,0-.26-1.358,2.743,2.743,0,0,0-.645-.991A2.741,2.741,0,0,0,9.256.294,4.106,4.106,0,0,0,7.9.034C7.3.006,7.112,0,5.593,0" transform="translate(2167 9029)" fill="#56575a"/>*/}
                                    {/*                <path id="Path_5913" data-name="Path 5913" d="M23.548,20.676a2.872,2.872,0,1,0,2.872,2.872,2.872,2.872,0,0,0-2.872-2.872m0,4.736a1.864,1.864,0,1,1,1.864-1.864,1.864,1.864,0,0,1-1.864,1.864" transform="translate(2149.045 9011.045)" fill="#56575a"/>*/}
                                    {/*                <path id="Path_5914" data-name="Path 5914" d="M61.429,15.384a.671.671,0,1,1-.671-.671.671.671,0,0,1,.671.671" transform="translate(2114.82 9016.224)" fill="#56575a"/>*/}
                                    {/*            </g>*/}
                                    {/*        </svg>*/}
                                    {/*    </a>*/}
                                    {/*</li>*/}
                                    <li>
                                        <a rel="nofollow" href="https://www.linkedin.com/company/brissie-estate-ltd/"
                                           target={'_blank'} className={'hover'}>
                                            <svg id="Group_28" data-name="Group 28" xmlns="http://www.w3.org/2000/svg" width="9.063" height="8.562" viewBox="0 0 9.063 8.562">
                                                <path id="LinkedIn" d="M1096.5,3921.554v3.313h-1.942v-3.091c0-.776-.281-1.306-.984-1.306a1.061,1.061,0,0,0-1,.7,1.3,1.3,0,0,0-.065.469v3.226h-1.943s.026-5.234,0-5.777h1.943v.82l-.013.018h.013v-.018a1.932,1.932,0,0,1,1.751-.955C1095.539,3918.955,1096.5,3919.781,1096.5,3921.554Zm-7.964-5.249a1,1,0,1,0-.026,2h.013a1,1,0,1,0,.013-2Zm-.984,8.561h1.943v-5.777h-1.943Z" transform="translate(-1087.435 -3916.306)" fill="#56575a"/>
                                            </svg>
                                        </a>
                                    </li>
                                    <li>
                                        <a rel="nofollow" href=""
                                           target={'_blank'} className={'hover'}>
                                            <svg id="Group_31" data-name="Group 31" xmlns="http://www.w3.org/2000/svg" width="9.97" height="6.979" viewBox="0 0 9.97 6.979">
                                                <path id="Path_29" data-name="Path 29" d="M1176.069,3919.422c-.36-.425-1.025-.6-2.294-.6h-4.606c-1.3,0-1.974.185-2.333.638a3.24,3.24,0,0,0-.349,1.993v1.717c0,1.745.415,2.631,2.682,2.631h4.606a2.913,2.913,0,0,0,2.1-.529,2.759,2.759,0,0,0,.577-2.1v-1.717A3.158,3.158,0,0,0,1176.069,3919.422Zm-3.181,3.128-2.092,1.087a.322.322,0,0,1-.47-.284v-2.168a.32.32,0,0,1,.154-.273.325.325,0,0,1,.315-.011l2.092,1.081a.319.319,0,0,1,0,.568Z" transform="translate(-1166.487 -3918.823)" fill="#56575a"/>
                                            </svg>

                                        </a>
                                    </li>

                                </ul>
                            </div>
                        </Col>
                        <Col md={10} className={'d-flex flex-wrap justify-content-center'}>
                            <a style={{width: 'auto'}} rel="nofollow" href={'https://www.google.com/maps/search/TSL+Tower+(7th+Floor),+43,+Sonargaon+Janapath,++Sector-+12,+Uttara,+Dhaka-+1230/@23.8739662,90.3772844,17z/data=!3m1!4b1?entry=ttu'} target={'_blank'}>
                                <p>TSL Tower (7th Floor), 43, Sonargaon Janapath, <br/> Sector- 12, Uttara, Dhaka- 1230</p>
                            </a>
                        </Col>
                    </Row>
                    <Row className={'footer__menu'}>
                        <Col>
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/who-we-are">Who We Are</Link></li>
                                <li><Link to="/projects">Projects</Link></li>
                                <li><Link to="/landowner">Respected Land Owner</Link></li>
                                <li><Link to="/buyer">Forthcoming Buyer</Link></li>
                                <li><Link to="/media-center">Media</Link></li>
                                <li><Link to="/contact">Contact</Link></li>
                            </ul>
                        </Col>

                        <div className="footer-social">
                            {/*<div className="button-wrap">*/}
                            {/*    <Button text={'DOWNLOAD BROCHURE'}/>*/}
                            {/*    <img src={Download} alt=""/>*/}
                            {/*</div>*/}
                            <ul>
                                <li><Link href="https://www.facebook.com/"><a rel="nofollow" target={'_blank'}
                                                                              className={'hover'}>
                                    <svg width="13.004" height="24.279"
                                         viewBox="0 0 13.004 24.279">
                                        <path id="Path_2112" data-name="Path 2112"
                                              d="M1210.939,109.757l.674-4.394H1207.4v-2.851a2.2,2.2,0,0,1,2.477-2.374h1.917V96.4a23.374,23.374,0,0,0-3.4-.3c-3.473,0-5.742,2.1-5.742,5.914v3.349h-3.86v4.394h3.86v10.622h4.75V109.757Z"
                                              transform="translate(-1198.787 -96.099)" fill="#fff"/>
                                    </svg>

                                </a></Link></li>

                                <li><Link href="https://www.linkedin.com/company/"><a rel="nofollow" target={'_blank'}
                                                                                      className={'hover'}>
                                    <svg width="20" height="19.999" viewBox="0 0 20 19.999">
                                        <path id="Icon_awesome-linkedin-in" data-name="Icon awesome-linkedin-in"
                                              d="M4.477,20H.33V6.648H4.477ZM2.4,4.826A2.413,2.413,0,1,1,4.8,2.4,2.422,2.422,0,0,1,2.4,4.826ZM20,20H15.858V13.5c0-1.549-.031-3.536-2.156-3.536-2.156,0-2.486,1.683-2.486,3.424V20H7.074V6.648h3.977V8.469h.058a4.357,4.357,0,0,1,3.923-2.156c4.2,0,4.968,2.763,4.968,6.353V20Z"
                                              transform="translate(0 -0.001)" fill="#fff"/>
                                    </svg>


                                </a></Link></li>
                                <li><Link href="https://www.instagram.com/"><a rel="nofollow" target={'_blank'}
                                                                               className={'hover'}>
                                    <svg id="Group_1420" data-name="Group 1420" xmlns="http://www.w3.org/2000/svg"
                                         width="24.129" height="24.129" viewBox="0 0 24.129 24.129">
                                        <g id="Group_1419" data-name="Group 1419" transform="translate(0 0)">
                                            <path id="Path_2109" data-name="Path 2109"
                                                  d="M1096.33,105.945a1.415,1.415,0,1,0,1.414,1.415A1.414,1.414,0,0,0,1096.33,105.945Z"
                                                  transform="translate(-1077.878 -101.637)" fill="#fff"/>
                                            <path id="Path_2110" data-name="Path 2110"
                                                  d="M1085,108.605a5.942,5.942,0,1,0,5.943,5.943A5.949,5.949,0,0,0,1085,108.605Zm0,9.749a3.806,3.806,0,1,1,3.806-3.806A3.81,3.81,0,0,1,1085,118.354Z"
                                                  transform="translate(-1072.833 -102.483)" fill="#fff"/>
                                            <path id="Path_2111" data-name="Path 2111"
                                                  d="M1086.813,123.757h-9.634a7.256,7.256,0,0,1-7.248-7.248v-9.634a7.256,7.256,0,0,1,7.248-7.247h9.634a7.256,7.256,0,0,1,7.248,7.247v9.634A7.256,7.256,0,0,1,1086.813,123.757ZM1077.18,101.9a4.983,4.983,0,0,0-4.978,4.977v9.634a4.983,4.983,0,0,0,4.978,4.978h9.634a4.983,4.983,0,0,0,4.978-4.978v-9.634a4.983,4.983,0,0,0-4.978-4.977Z"
                                                  transform="translate(-1069.932 -99.628)" fill="#fff"/>
                                        </g>
                                    </svg>

                                </a></Link></li>
                                <li><Link href="https://www.youtube.com/"><a rel="nofollow"
                                    target={'_blank'} className={'hover'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21.248" height="14.873"
                                         viewBox="0 0 21.248 14.873">
                                        <path id="Path_2114" data-name="Path 2114"
                                              d="M1155.323,114.98a4.663,4.663,0,0,0-4.663-4.663h-11.923a4.663,4.663,0,0,0-4.663,4.663v5.549a4.663,4.663,0,0,0,4.663,4.663h11.923a4.663,4.663,0,0,0,4.663-4.663Zm-7.011,3.19-5.347,2.645c-.209.113-.922-.038-.922-.277v-5.43c0-.241.718-.393.927-.273l5.118,2.784C1148.3,117.742,1148.529,118.052,1148.312,118.17Z"
                                              transform="translate(-1134.075 -110.317)" fill="#fff"/>
                                    </svg>

                                </a></Link></li>

                            </ul>
                        </div>
                    </Row>


                    <Row className={'last-row'}>
                        <Col md={6} className={'footer__copyright d-flex justify-content-start split-ip'}>
                            <div className={'align-items-start'}>
                                <p>© {new Date().getFullYear()} Brissie Estate Limited. All Rights Reserved.</p>


                            </div>
                            {/*<a href={'/'}>Privacy Policy</a>*/}
                        </Col>
                        <Col md={6} className={'footer__copyright d-flex justify-content-end split-ip'}>
                            <div className={'align-items-center'}>
                                <div className={'d-flex justify-content-end align-items-center'}>
                                    <a  href={'https://dcastalia.com/'} target={'_blank'} rel="nofollow">Designed & Developed by Dcastalia </a>
                                    <img className={'ml-3'} src="/images/static/dclogo.svg" alt="Designed & Developed by Dcastalia"/>
                                </div>

                            </div>
                            {/*<a href={'/'}>Privacy Policy</a>*/}
                        </Col>
                    </Row>

                </Container>
            </Footer>
        </>
    );
};

const Footer = styled.section`
    background-color: #FFFFFF;
    position: relative;
    padding-top: 77px;


    .bg-noise {
        position: absolute;
        inset: 0;
        overflow: hidden;
        width: 100%;
        height: 100%;
        background-color: #FFFFFF;

        &:after {
            content: "";
            background-image: url('/images/static/noise-new.svg');
            background-blend-mode: multiply;
            background-size: cover;
            background-repeat: no-repeat;
            width: 100%;
            height: 100%;
            position: absolute;
            inset: 0;
            opacity: 1
        }
    }


    .shadow {
        position: absolute;
        bottom: 0;
        right: 0;
        box-shadow: 0 0 0 transparent !important;
        //transform: translateX(75%);

        //svg {
        //  width: 40%;
        //}
    }

    @media (max-width: 767px) {
        .shadow {
            right: -35%;
            //transform: translateX(75%);

            svg {
                width: 65% !important;
                height: 50%;
                //height: 40px;
            }
        }
    }

    img.top-icon {
        position: absolute;
        top: -94px;
        left: 0;
    }

    .footer__top {
        position: relative;

        &:after {
            position: absolute;
            //height: 50%;
            height: calc(50% + 100px);
            width: 100%;
            content: '';
            left: 0;
            right: 0;
            top: 0;
            //bottom: 0;
            background-color: ${p => p.footerTopBg || '#FFF'};
        }

        &__wrap {
            background-color: #565440;
            padding: 100px 70px 100px 100px;
            margin-bottom: 120px;
            width: 100%;
            position: relative;
            z-index: 2;
        }

        h2 {
            color: #e9e4dc;
        }

        .form__inner {
            width: calc(100% - 140px);
            display: flex;
            padding-top: 75px;

            .col-sm-2 {
                min-width: 25%;
            }

            .form-control {
                ::placeholder {
                    color: rgba(233, 228, 220, 0.50) !important;
                }

                border-color: #E9E4DC;
            }

            .filter__placeholder {
                color: rgba(233, 228, 220, 0.50) !important;
            }

            .css-yk16xz-control {
                border-color: #E9E4DC !important;
            }

        }

        .btn-submit {
            width: 140px;
            padding-left: 20px;

            p {
                padding-top: 100%;
                width: 100%;
                border: 1px solid #E9E4DC;
                border-radius: 100%;
                //cursor: pointer;

                &:after {
                    background-color: #E9E4DC;
                    border-radius: 100%;
                }

                span {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    height: fit-content;
                    margin: auto;
                    left: 0;
                    right: 0;
                    width: fit-content;
                    text-transform: uppercase;
                    font-size: 16px;
                    line-height: 28px;
                    color: #E9E4DC;
                    transition: color .3s ease;
                }

                &:hover {
                    span {
                        color: #565440;
                    }
                }
            }
        }
    }

    .footer__big-number {
        text-align: center;

        ul {
            margin-bottom: 15px;
        }

        a {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;

            svg {
                //margin-right: 5px;
            }

            &.email {
                font-size: 24px;
                line-height: 30px;
                color: #56575A;
                display: block;
                text-align: center;
                margin-bottom: 30px;
                @media (max-width: 767px) {
                    text-align: left;
                }
            }

            &.big-number {
                font-size: 24px;
                line-height: 30px;
                color: #56575A;
                display: block;
                text-align: center;
                    //font-family: ${title};
                width: fit-content;
                font-weight: 500;
                margin: auto;
                margin-bottom: 20px;

            }
        }

        p {
            width: 100%;
            font-size: 16px !important;
            line-height: 24px !important;
            font-weight: 400;
            letter-spacing: 0;
            color: #56575A;

            &:hover {
                color: ${hover};
            }

        }
    }

    .footer-social {
        ul {
            display: flex;
            width: 100%;
            margin: 0 !important;

            li {
                a {
                    display: flex;
                    height: 40px;
                    width: 40px !important;
                    border-radius: 50%;
                    background-color: #FFF;
                    align-items: center;
                    justify-content: center;
                    color: #ffffff;

                    svg {
                        position: relative;
                        z-index: 2;
                        width: 14px;
                        height: 12px;

                        path {
                            //transition: color ;
                        }
                    }

                    &:hover {
                        path {
                            fill: #FFF;
                        }
                    }
                }

                .hover:after {
                    background-color: ${hover};
                }

                &:not(:nth-last-of-type(1)) {
                    margin-right: 20px;
                }
            }
        }
    }

    .footer__menu {
        ul {
            display: flex;
            justify-content: center;
            margin-top: 70px;
            flex-wrap: wrap;

            li {
                a {
                    //text-transform: uppercase;
                    color: #56575A;
                    font-size: 16px;
                    line-height: 24px;
                    //letter-spacing: 1.2px;
                    font-weight: 500;
                }

                &:not(:nth-last-of-type(1)) {
                    margin-right: 70px;
                }
            }
        }

        .footer-social {
            margin-top: 40px;
            width: 100%;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            @media (max-width: 767px) {
                display: none;
            }

            ul {
                li {
                    width: auto !important;
                    min-width: unset;
                    max-width: unset;
                    margin-right: 20px !important;
                }
            }

            .button-wrap {
                width: 50%;
                @media (min-width: 768px) {
                    display: none;
                }

                .dc-btn {
                    width: 100%;

                    a {
                        width: 100% !important;
                    }
                }


                img {
                    bottom: 0;
                }

                @media (max-width: 767px) {
                    //padding-left: 15px;
                    padding-right: 15px;
                    width: 100%;
                    img {
                        right: 35px;
                    }
                }
            }

            .dc-btn a {
                &:before {
                    width: 100%;
                }

                &:after {
                    display: none;
                }
            }

            ul {
                margin-top: 30px;
            }
        }

        @media (min-width: 768px) {
            .footer-social {
                display: none;

                ul {
                    display: none;
                }
            }
        }
    }

    .footer__social {
        margin-bottom: 30px;

        ul li a {
            background: #F2F2F2;
        }

        img {
            height: 60px;
            @media (max-width: 767px) {
                width: 180px;
            }
        }
    }


    .last-row {
        position: relative;
        margin-top: 30px;

        &:after {
            content: '';
            width: calc(100% - 30px);
            margin: 0 auto;
            height: 1px;
            background: ${text};
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
        }
    }

    .footer__copyright {
        padding-top: 30px;
        padding-bottom: 40px;
        width: 100%;

        a, p {
            font-size: 12px;
            line-height: 16px;
            color: rgba(25, 24, 24, 0.5);
        }

        @media (max-width: 767px) {
            &:first-child {
                padding-bottom: 10px;
            }

            &:last-child {
                margin-top: 0;
                padding-top: 0;
                display: block !important;
                text-align: left !important;

                .justify-content-end {
                    justify-content: flex-start !important;
                }

                a:nth-last-of-type(1) {
                    margin-top: 0;
                    display: inline-block;
                    min-width: unset;
                }
            }
        }
    }

    .button-wrap {
        position: relative;
        width: fit-content;

        img {
            position: absolute;
            right: 18px;
            height: 15px;
            top: 0;
            bottom: 7px;
            margin: auto;
        }

        .dc-btn a {
            padding-right: 38px;
        }
    }

    @media (max-width: 900px) {
        .footer__menu {
            ul {
                margin-top: 60px;
                justify-content: start;

                li {
                    min-width: 33.333%;
                    max-width: 33.333%;
                    margin-right: 0 !important;
                    text-align: center;
                    margin-bottom: 20px;
                }
            }
        }
    }

    @media (max-width: 768px) {
        img.top-icon {
            top: -50px;
            height: 50px;
        }
    }

    @media (max-width: 767px) {

        .footer__big-number {
            text-align: left !important;

            .justify-content-center {
                justify-content: flex-start !important;
            }

            a.big-number {
                margin: 0 0 30px;
                text-align: left;
            }

            ul {
                li {
                    margin-bottom: 10px;

                    a {
                        text-align: left !important;
                        margin: 0;
                        width: fit-content;
                    }
                }
            }
        }

        .footer__menu {
            ul {
                margin-top: 40px;

                li {
                    max-width: 100%;
                    min-width: 100%;
                    text-align: left;

                    a {
                        font-size: 14px;
                        line-height: 18px;
                    }
                }
            }

        }

        .footer__social {
            .col-sm-4 {
                min-width: 100%;
                justify-content: flex-start !important;

                &:nth-of-type(1) {
                    display: none;
                }

                &:nth-last-of-type(1) {
                    //display: none !important;

                }

            }

        }

        .footer__copyright {
            margin-top: 20px;
            flex-wrap: wrap;
            justify-content: start !important;

            p, a {
                min-width: 100%;
            }

            p {
                margin-top: 5px;
            }

            a:nth-last-of-type(1) {
                //display: none;
                margin-top: 5px;
            }

        }

    }

    @media (max-width: 991px) {
        .footer__top__wrap {
            padding: 50px 30px;
        }
    }

    @media (max-width: 850px) {
        .footer__top__wrap {
            padding: 60px 0px 60px 15px;
        }

        .form__inner {
            flex-wrap: wrap;
            min-width: 100%;
            padding-top: 40px !important;

            .col-sm-2 {
                min-width: 50% !important;

                &:nth-of-type(3) {
                    padding-left: 0;
                }
            }
        }

        .form-control {
            margin-bottom: 15px !important;
        }

        .footer__top .btn-submit {
            padding-left: 0;
            width: 50%;
            margin-top: 20px;


            p {
                padding-top: 0px;
                height: 39px;
                border-radius: 25px;
                background-color: #E9E4DC;
                border-color: #C49F8A;

                span {
                    font-size: 12px;
                    line-height: 20px;
                    color: #565440;
                }
            }
        }
    }

    @media (max-width: 767px) {

        .footer__big-number .justify-content-center{
            align-items: flex-start !important;
        }
        
        .footer-social {
            //padding-left: 15px;

            ul {
                justify-content: flex-start;
            }
        }
    }

    @media (max-width: 575px) {
        .footer__top {
            padding-top: 0 !important;

            .container {
                padding-left: 0;
                padding-right: 0;
            }

            &__wrap {
                margin-bottom: 100px;
            }

            h2 {
                font-size: 24px;
                line-height: 32px;
            }

            .form__inner .col-sm-2 {
                padding-left: 0;
            }

            .btn-submit {
                margin-top: 10px;
                width: calc(100% - 15px)
            }
        }
    }


`;

export default MyComponent;
