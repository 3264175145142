import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchLandowner, fetchPosts} from "../../api/redux/buyer";
import {Helmet, HelmetProvider} from "react-helmet-async";
import InnerBanner from "../../components/InnerBanner";
import Overview from "../../components/Overview";
import BuyerForm from "./BuyerForm";
import MetaTag from "../../components/meta/MetaTag";


const MyComponent = () => {


    const dispath = useDispatch();

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.BUYER
        dispath(fetchLandowner([api_url]))
    }, [])

    let getPost = useSelector(state => state.buyer)


    // data refactor

    let banner=getPost?.posts?.[0]?.banner;
    let overview=getPost?.posts?.[0]?.overview;
    let form=getPost?.posts?.[0]?.form;
    let meta=getPost?.posts?.[0]?.meta;



    return (
        <HelmetProvider>
            <MetaTag title={'Forthcoming Buyer | Brissie Estate Limited.'} description={meta?.meta_desc ? meta?.meta_desc : ''} ogTitle={meta?.og_title ? meta?.og_title : ""} ogDescription={meta?.og_desc ? meta?.og_desc : ''}/>

            <StyledComponent>
                <InnerBanner notFull title={banner?.title} subtext={banner?.subtitle}
                             img={banner?.image} mimg={banner?.mobile_image}/>
                <Overview bgcolor={'#F9F9F9'} onlyDescription  desc={overview?.description}/>
                <BuyerForm data={form}/>
            </StyledComponent>
        </HelmetProvider>
    );
};
const StyledComponent = styled.section`
`
export default MyComponent;