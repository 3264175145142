import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Modal from "react-bootstrap/Modal";
import {Col, Container, Form, Row} from "react-bootstrap";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import CloseButton from "./CloseButton";
import {useDispatch} from "react-redux";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {apiEndPoints} from "../api/network/apiEndPoints";
import {postForm} from "../api/redux/project";
import {text} from "../styles/globalStyleVars";
import ButtonV2 from "./ButtonV2";

const PopupV1 = ({
                     project_name,
                     show,
                     handleClose,
                     no_img,
                     item,
                     title,
                     description,
                     data,
                     subtitle,
                     img
                 }) => {


    const popupData = data;
    const [winWidth, setWinWidth] = useState(true)
    useEffect(() => {
        if (window.innerWidth > 767) {
            setWinWidth(true)
        } else {
            setWinWidth(false)
        }
        window.addEventListener("resize", () => {
            if (window.innerWidth > 767) {
                setWinWidth(true)
            } else {
                setWinWidth(false)
            }
        });
    }, [])


    const dispatch = useDispatch();

    const {register, handleSubmit, formState, reset, control} = useForm({mode: 'all'});

    //--- form submit
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });


    const onSubmit = (e) => {


        let api_services = apiEndPoints.BROCHURE_FORM;

        var formData = new FormData();
        formData.append('email', e?.email);
        formData.append('file', data);
        formData.append('project_name', project_name);

        if (e.email !== '') {
            dispatch(postForm([api_services, formData]));
            success('Successfully Submitted')
            reset();
            handleClose(true)
            setTimeout(() => {
                window.open(data, '_blank');
            }, 2000); // 2000 milliseconds = 2 seconds
        }
    };
    let count = 0;
    const onError = (errors) => {
        Object.values(errors).forEach((error) => {
            count++;
        });
        if (count > 0) {
            toast.error('Please fill out the correct inputs');
        }
        count = 0;
    };


    const option = [
        {value: 'option1', label: 'Select 1'},
        {value: 'option2', label: 'Select 2'},
    ]

    const customStyles = {
        dropdownIndicator: (base, state) => ({
            ...base,
            transition: "all .2s ease",
            transform: state.selectProps.menuIsOpen && "rotate(180deg)"
        }),
        option: (provided, state) => ({
            ...provided,
            borderRadius: 0,
            color: state.isSelected ? `${text}` : '#221F1F',
            backgroundColor: state.isSelected ? `rgba(232, 231, 231, 0.6)` : '#FFF',
            margin: 0,
            fontSize: 14,
            // cursor: 'pointer',
            paddingLeft: 10,
            paddingRight: 10,
            fontWeight: state.isSelected ? 700 : 400,
            "&:hover": {
                backgroundColor: `${text}`,
                color: '#FFF',
                // cursor: 'pointer'
            },

        }), menu: (provided, state) => ({
            ...provided,
            color: '#FFF',
            backgroundColor: state.isSelected ? `${text}` : 'rgba(255,255,255,0)',
            margin: 0,
            borderRadius: 5,
            fontSize: 14,
            // width: 200,
        }), menuList: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#191818' : '#FFF',
            borderRadius: 0,
            fontWeight: '400',
            color: '#FFF',
            fontSize: 12,
        }),

    };

    const CaretDownIcon = () => {
        return <svg xmlns="http://www.w3.org/2000/svg" width="13.414" height="7.414" viewBox="0 0 13.414 7.414">
            <g id="Group_15659" data-name="Group 15659" transform="translate(1479.207 -192.793) rotate(90)">
                <line id="Line_3883" data-name="Line 3883" x2="6" y2="6" transform="translate(193.5 1466.5)" fill="none" stroke="#FFFFFF" stroke-linecap="round" stroke-width="1"/>
                <line id="Line_3884" data-name="Line 3884" y1="6" x2="6" transform="translate(193.5 1472.5)" fill="none" stroke="#FFFFFF" stroke-linecap="round" stroke-width="1"/>
            </g>
        </svg>
            ;
    };


    return (

        <StyledModal>
            <Modal
                show={show}
                item={item}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="gph_modal popup-version-one popup brochure_button"
            >
                <SimpleBar className="main_scroll" style={{height: '100vh'}}>
                    <Modal.Body>
                        <Container>
                            <Row className={'for-close'}>
                                <div onClick={handleClose} className="close-area ">
                                    <CloseButton/>
                                </div>
                            </Row>
                        </Container>
                        <Container>
                            <Row>
                                <div className="modal-data d-flex">

                                    <Col sm={{span: 12}} className='modal-data__content'>
                                        <div className="scroll-div">
                                            <Col md={12} className={'form-wrapper'}>
                                                <Form onSubmit={handleSubmit(onSubmit, onError)}>
                                                    <Row>
                                                        <Col md={`12`} className={'profile'}>

                                                            <Form.Control
                                                                className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                                type="email" placeholder="Email"
                                                                {...register("email", {
                                                                    required: {
                                                                        value: true,
                                                                        message: 'Please enter your email'
                                                                    },
                                                                    pattern: {
                                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                                        message: 'Please enter a valid email address'
                                                                    }
                                                                })}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <div className={'d-flex justify-content-start mobile'}>
                                                        <div onClick={handleSubmit(onSubmit, onError)}>
                                                            <ButtonV2 margin={'40px 0 0'} text={'Download Brochure'}
                                                                      borderColor={'#FFFFFF'}
                                                                      hoverBorder={'#F16A11'}
                                                                      color={'#FFFFFF'}
                                                                      fontWeight={'700'}
                                                                      background={'transparent'}
                                                                      hoverBackground={'#F16A11'}
                                                            />
                                                        </div>
                                                    </div>
                                                </Form>
                                            </Col>
                                        </div>
                                    </Col>


                                </div>
                            </Row>
                        </Container>
                    </Modal.Body>
                </SimpleBar>
            </Modal>
        </StyledModal>

    )
};


const StyledModal = styled.div`

    .modal-dialog {
        margin: 0;
    }

    h5 {
        color: #EDEEEF !important;
    }

    .footer__social {
        .col-sm-4 {
            min-width: 100%;
            justify-content: flex-start !important;

            &:nth-of-type(1) {
                display: none;
            }

            &:nth-last-of-type(1) {
                //display: none !important;
            }
        }
    }

`;


export default React.memo(PopupV1);
