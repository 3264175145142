import { useEffect } from 'react';

const useImageAndTextAnimationHome = (data, imageIndex) => {
    useEffect(() => {
        if (data?.length > 0) {
            const getAllImg = document.querySelectorAll('.banner-animation image');
            const activeImage = document.querySelector('.banner-animation .active');

            if (activeImage) {
                activeImage.classList.remove('active');
            }
            if (imageIndex > 0 && getAllImg[imageIndex - 1]) {
                getAllImg[imageIndex - 1].classList.add('active');
            }
        }

        // if (data) {
        //     const getAllText = document.querySelectorAll('.text-slider__content');
        //
        //     getAllText.forEach(element => {
        //         const handleMouseOver = () => {
        //             element.classList.add('mouse-over');
        //         };
        //         const handleMouseLeave = () => {
        //             element.classList.remove('mouse-over');
        //         };
        //
        //         element.addEventListener('mouseover', handleMouseOver);
        //         element.addEventListener('mouseleave', handleMouseLeave);
        //
        //         return () => {
        //             element.removeEventListener('mouseover', handleMouseOver);
        //             element.removeEventListener('mouseleave', handleMouseLeave);
        //         };
        //     });
        // }
    }, [data, imageIndex]);
};

export default useImageAndTextAnimationHome;
