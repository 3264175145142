import React from 'react';
import SliderPrevWhite from './SliderPrevWhite'; // Assuming you have these components
import SliderNextWhite from './SliderNextWhite'; // Assuming you have these components

const SliderButtonBressie = ({prevClass, nextClass, prevId, nextID}) => {
    return (
        <div className="blog-button">
            <div className="slider-nav">
                <ul>
                    <li id={`${prevId ? prevId : ''}`} className={`${prevClass ? prevClass : 'slider_prev'}`}>
                        <SliderPrevWhite
                            color={'#FFFFFF'}
                            background={'#24717D'}
                            hoverBackground={'#285854'}
                            hoverColor={'#FFFFFF'}
                            hoverBorder={'#285854'}
                        />
                    </li>
                    <li id={`${nextID ? nextID : ''}`} className={`${nextClass ? nextClass : 'slider_next'}`}>
                        <SliderNextWhite
                            color={'#FFFFFF'}
                            background={'#24717D'}
                            hoverBackground={'#285854'}
                            hoverColor={'#FFFFFF'}
                            hoverBorder={'#285854'}
                        />
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default SliderButtonBressie;
