import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {fetchHome, fetchPosts} from "../../api/redux/home";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import styled from "styled-components";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import HomeOverview from "./HomeOverview";
import Video from "../../components/Video";
import WhyUs from "./WhyUs";
import FeatureSlider from "./FeatureSlider";
import HomeBanner from "./HomeBanner";
import ContactForm from "../contact/ContactForm";
import MetaTag from "../../components/meta/MetaTag";
import useScrollToTop from "../../hooks/useScrollToTop";
const Home = () => {

    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.HOME
        dispath(fetchHome([api_url]))
    }, [])
    let getPost = useSelector(state => state.home)



    // data refactor

    let banner=getPost?.posts?.page_data?.banner?.list;
    let about=getPost?.posts?.page_data?.about;
    let form=getPost?.posts?.page_data?.form;
    let why_us=getPost?.posts?.page_data?.why_us;
    let video=getPost?.posts?.page_data?.video;
    let project_list=getPost?.posts?.projects?.filter(element => element?.isFeatured === 'on');;
    let meta=getPost?.posts?.page_data?.meta;



    useScrollToTop([getPost]);


    return (
        <HelmetProvider>
            <MetaTag title={'Brissie Estate Limited.'} description={meta?.meta_desc ? meta?.meta_desc : ''} ogTitle={meta?.og_title ? meta?.og_title : ""} ogDescription={meta?.og_desc ? meta?.og_desc : ''}/>
            <StyledSection>
                <HomeBanner data={banner}/>
                <FeatureSlider data={project_list}/>
                <HomeOverview imageSrc={about?.bg_image} desc={about?.description} title={about?.subtitle} hasbtn bg={'/images/dynamic/home/bg.jpg'}/>
                <WhyUs data={why_us}/>
                <Video sectionGap={''} data={video}/>
                <ContactForm data={form}/>
            </StyledSection>
        </HelmetProvider>
    );
};

const StyledSection = styled.section`

`;

export default Home;
