import React, {useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {Img} from "../../components/Img";
import {hover} from "../../styles/globalStyleVars";
import title from "../../components/Title";
import Title from "../../components/Title";
import PopupV1 from "../../components/PopupV1"
import reactHtmlParser from "react-html-parser";
import ExpertItem from "../../components/about/parts/ExpertItem";
import ThemeRow from "../../theme/row";


const RandomSliderV1 = ({offset,title , padding, description,testimonial, image}) => {


    return (
        <StyledBlog offset={offset} className={`blog-slider  ${padding ? padding : 'pt-150'}`} testimonial={testimonial}>


            <Container>
                <Row>
                    <Col md={5}>
                        <div className="image-wrapper">
                            <Img src={image ? image :'images/static/male-avatar-placeholder.png'}/>
                        </div>
                    </Col>
                    <Col md={{span: 6, offset: 1}}>
                        <Title margin={'0 0 40px 0'} fontSize={48} fontWeight={500} lineHeight={56} color={'#285854'} noanim text={title ? title :'CEO Message'}/>
                        {
                            description &&
                            reactHtmlParser(description)
                        }
                    </Col>
                </Row>

            </Container>

        </StyledBlog>
    );
};

const StyledBlog = styled.section`

    .image-wrapper {
        padding-top: 120%;
        position: relative;
        height: 100%;
    }


    p {
        color: black;
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        font-family: Aeonik;
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }


    @media (max-width: 992px) {
        .col-md-5, .col-md-6.offset-md-1 {
            flex: 0 0 100%;
            max-width: 100%;
            margin: 0;
        }

        .col-md-6.offset-md-1 {
            margin-top: 40px;
        }

    }

`;
export default React.memo(RandomSliderV1);
