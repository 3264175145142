import React, {useState} from 'react';
import styled from "styled-components";
import 'react-modal-video/css/modal-video.min.css';
import ReactHtmlParser from "react-html-parser";

const MyComponent = ({title, des, opacity, icon}) => {

    return (
        <StyledComponent opacity={opacity} className={'d-flex justify-content-center align-items-center'}>
            <div>
                <div className='feature__single__img'>
                    <img src={icon} alt={ReactHtmlParser(title)}/>
                </div>
                <div className={'feature__single__name'}>
                    <h6>{ReactHtmlParser(title)}</h6>
                </div>
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`

    position: absolute;
    inset: 0;


    .feature__single {
        &__name {
            //position: absolute;
            //top: 135px;
            //left: 20px;
            //right: 20px;
            //@media (min-width: 1500px) {
            //  top: 62%;
            //}

            padding-left: 10px;
            padding-right: 10px;

            h6 {
                text-transform: uppercase;
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                letter-spacing: 1.4px;
                text-align: center;
                //height: 44px;
                color: #56575A;
                font-family: 'Aeonik';
                
                @media(max-width: 992px){
                    font-size: 12px;
                    line-height: 18px;
                }

            }
        }

        &__img {
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;
            height: 90px;
            width: 90px;
            width: 100%;

            img {

                margin-bottom: 20px;
            }

        }

    }

`;

export default MyComponent;
