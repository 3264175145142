import React from 'react';

const CloseIconModal = () => {
    return (
        <svg  className={'close-modal'} xmlns="http://www.w3.org/2000/svg" width="140"
             height="140"
             viewBox="0 0 140 140">
            <path id="Icon_ionic-ios-close" data-name="Icon ionic-ios-close"
                  d="M19.589,18l4.8-4.8A1.124,1.124,0,0,0,22.8,11.616l-4.8,4.8-4.8-4.8A1.124,1.124,0,1,0,11.616,13.2l4.8,4.8-4.8,4.8A1.124,1.124,0,0,0,13.2,24.384l4.8-4.8,4.8,4.8A1.124,1.124,0,1,0,24.384,22.8Z"
                  transform="translate(-11.285 -11.289)" fill="#fff"/>
        </svg>
    );
}

export default CloseIconModal;
