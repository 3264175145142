import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "../../../components/Img";
import Title from "../../../components/Title";
import reactHtmlParser from "react-html-parser";
import {Autoplay, Navigation, Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import {hover} from "../../../styles/globalStyleVars";
import ThemeRow from "../../../theme/row";

const MyComponent = ({data}) => {

    return (
        <StyledComponent className="at-a-glance ">
            <ThemeRow fluid>
                <Col md={8} className={'at-a-glance__left'}>
                    <Title text={data?.title} margin={'0 0 40px'} fontSize={40} fontWeight={500} lineHeight={48} color={'#EDEEEF'} />
                    <ul >
                        {
                            data?.list && data?.list?.length>0 &&
                            data?.list?.map((element)=>{
                                return(
                                    <li>
                                        <div className="icon">
                                            <img src={element?.icon}/>
                                        </div>
                                        <div className="content">
                                            <div className="item"><p>{reactHtmlParser(element?.title)}</p></div>
                                            <div className="value"><p>{reactHtmlParser(element?.short_desc)}</p></div>
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </Col>
                <Col md={4} className={'at-a-glance__right'}>
                    {
                        data?.images && data?.images?.length>0 &&
                        <Swiper
                            loop={false}
                            spaceBetween={30}
                            slidesPerView={1}
                            slidesPerGroup={1}
                            allowSlideNext={true}
                            allowSlidePrev={true}
                            allowTouchMove={false}
                            speed={900}
                            // infinity={true}
                            pagination={{
                                clickable: true
                            }}
                            navigation={{
                                prevEl: '.slider_prev',
                                nextEl: '.slider_next ',
                            }}
                            modules={[Autoplay, Pagination, Navigation]}
                            // autoplay={{
                            //     delay: 4000,
                            //     disableOnInteraction: false,
                            // }}
                            style={{ zIndex: 1 }}
                        >
                            {
                                data?.images && data?.images?.length>0 &&
                                data?.images?.map((element,index)=>{
                                    return(
                                        <SwiperSlide key={index}>
                                            <div className="at-a-glance__right__image">
                                                <Img src={element?.image}/>
                                            </div>
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                    }

                    <div className="swiper-pagination" style={{ zIndex: 2 }}/>
                </Col>
            </ThemeRow>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    background: #285854;


    .at-a-glance {
        &__left {
            padding-left: 100px;
            padding-top: 120px;
            padding-bottom: 60px;


            ul {
                li {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 25px;
                    
                    &:last-child {
                        margin: 0;
                    }

                    .icon {
                        flex: 0 0 40px;

                        img {
                            //margin-top: -4px;
                        }
                    }

                    .content {
                        display: flex;
                        align-items: center;
                        flex: 0 0 calc(100% - 50px);

                        .item {
                            flex: 0 0 calc(50% - 50px);

                            p {
                                color: rgba(255, 255, 255, 0.5);
                                text-transform: uppercase;
                            }
                        }

                        .value {
                            p {
                                color: #EDEEEF;
                                text-transform: uppercase;
                            }
                        }
                    }

                }
            }
            
            p{
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                letter-spacing: 1.4px;
            }

        }

        &__right {
            padding: 0;
            position: relative;

            .swiper-pagination {
                position: absolute;
                bottom: 40px;
                width: 100%;
                z-index: 99999;
            }

            &__image {
                padding-top: calc(768 / 498 * 100%);
                min-height: 100%;

            }
        }
    }


    @media (max-width: 767px) {
        .at-a-glance {
            &__left {
                padding-left: 30px;
                padding-top: 80px;
                margin-top: 0px;

                h2 {
                    margin-left: 0;
                }

                ul {
                    li {
                        &:last-child {
                            margin: 0;
                        }

                        .content {
                            flex-direction: column;
                            align-items: start;
                        }
                    }
                }

            }

            &__right {
                padding: 0;

                &__image {
                    position: relative;
                    padding-top: calc(620 / 470 * 100%);
                    min-height: 100%;
                }
            }
        }
    }

    .swiper-pagination-bullet {
        background-color: #FFFFFF;
        opacity: 1;
        height: 5px;
        padding: 0;
        border-radius: 2px;
        width: 20px;
        margin-right: 20px !important;
        @media (max-width: 600px) {
            width: 15px;
            margin-right: 10px !important;
        }

        &.swiper-pagination-bullet-active {
            width: 60px;
            opacity: 1;
            background-color: ${hover};
            @media (max-width: 600px) {
                width: 25px;
            }
        }
    }

`;


export default MyComponent;
