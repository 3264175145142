import React from 'react';

const PlayIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="24" viewBox="0 0 18 24">
            <path id="Polygon_2" data-name="Polygon 2" d="M10.336,2.5a2,2,0,0,1,3.328,0l8.263,12.394A2,2,0,0,1,20.263,18H3.737a2,2,0,0,1-1.664-3.109Z" transform="translate(18) rotate(90)" fill="#285854"/>
        </svg>
    );
}

export default PlayIcon;
